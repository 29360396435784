@import '~core/styles/variables';

/* Colors */
$brandWhite: #fff;
$brandBlack: #000;
$brandYellow: #ffbf27;
$brandGreen: #008020;
$brandPrimaryColor: #304cb2; // blue
$brandSecondaryColor: #1a2c80; // dark blue
$brandTertiaryColor: #11172b;
$brandQuaternaryColor: #294299;
$brandGray: #636363;
$brandGrayLight: #e9e9e9;
$brandNeutralDarkColor2: #818181;
$brandVioletLight: #acb7e0;

$brandNeutralMediumColor: #a4baf2;
$brandNeutralDarkColor: #111b40;

/* Background */
$brandGradientColor: linear-gradient(180deg, $brandPrimaryColor 0%, $brandQuaternaryColor 100%);

/* Border */
$brandBorderColor: #e7ecef;

/* Favorites */
$brandUnselectedFavoriteOpacity: 0;

/* Content */
$contentWrapPadding: (small: 0 2rem, medium: 0 1rem, large: 0 3rem);

/* Typography */
// Text
$brandTextColor: #636363;
$brandTextColor2: #566f80;
$brandTextColorInverse: $brandWhite;
$brandFontFamily: Arial, Helvetica, sans-serif;
// Links
$brandLinkColor: $brandPrimaryColor;
$brandLinkColorHover: lighten($brandLinkColor, 5%);
// H1
$brandH1FontFamily: $brandFontFamily;
// H2
$brandH2FontSize: (small: 3rem, medium: 3rem);
$brandH2LineHeight: (small: 3.6rem, medium: 3.6rem);
$brandH2Color: $brandPrimaryColor;
$brandH2FontFamily: $brandFontFamily;
// H3
$brandH3FontSize: (small: 2rem, medium: 2.4rem);
$brandH3LineHeight: (small: 2.6rem, medium: 1.2);
$brandH3Color: $brandNeutralDarkColor;
$brandH3FontFamily: $brandFontFamily;

/* Favorite Icon */
$favoriteIconFillColor: ();
$favoriteIconStrokeColor: #999;
$favoriteIconStrokeWidth: 1px;
$favoriteIconFavoritedFillColor: #c32532;
$favoriteIconFavoritedStrokeColor: #c32532;

/* Balloon tooltip */
$balloonTooltipBackground: rgba(0, 0, 0, .7);
$balloonTooltipSVGFillColor: '%23000';
$balloonTooltipSVGStrokeColor: $balloonTooltipSVGFillColor;
$balloonSVGFillColor: $balloonTooltipSVGFillColor;
$balloonSVGStrokeColor: $balloonTooltipSVGFillColor;
$balloon-bg: $balloonTooltipBackground;
$balloonSVGStrokeOpacity: ();

/* Button */
$brandButtonBackgroundColor: #ffbf27;
$brandButtonBackgroundGradient: none;
$brandButtonShadow: none;
$brandButtonPadding: (small: 1.2rem 0);
$brandButtonFontFamily: $brandFontFamily;
$brandButtonFontSize: 1.7rem;
$brandButtonFontWeight: bold;
$brandButtonLineHeight: 2.4rem;
$brandButtonBorder: ();
$brandButtonBorderRadius: 2px;
$brandButtonColor: $brandTertiaryColor;
// Hover
$brandButtonColorHover: $brandTertiaryColor;
$brandButtonBackgroundGradientHover: none;
$brandButtonBackgroundColorHover: ();
$brandButtonBorderColorHover: ();
// Active
$brandButtonColorActive: $brandTertiaryColor;
$brandButtonBackgroundGradientActive: none;
$brandButtonBackgroundColorActive: ();
$brandButtonBorderColorActive: ();

/* Section Title */
$sectionTitleH2FontSize: (small: 3rem, medium: 3rem);
$sectionTitleH2Color: (small: $brandH2Color, medium: $brandH2Color);
$sectionTitleH2FontFamily: $brandFontFamily;
$sectionTitleH2FontWeight: (small: bold, medium: bold);
$sectionTitleH2LineHeight: (small: 3.6rem, medium: 3.6rem);
$sectionTitleH2LetterSpacing: ();
$sectionTitleLinkFontSize: 1.6rem;
$sectionTitleRowGap: (small: .5rem);
$sectionTitleLinkAfterContent: '\e005';
$sectionTitleLinkAfterFontFamily: 'swa-icon';
$sectionTitleLinkAfterFontSize: 1.2rem;
$sectionTitleLinkAfterMargin: 0 0 0 .8rem;
$sectionTitleLinkAfterPositionTop: .1rem;
$sectionTitleFlexDirection: (small: column, medium: row, xxlarge: column);
$sectionTitleAlignItems: (small: center, medium: baseline, xxlarge: flex-start);
$sectionTitleJustifyContent: (small: center, medium: space-between);

/* Lightbox */
$lightboxCloseIconContent: '\E010';
$lightboxCloseIconFontFamily: 'swa-icon';

/* Modal */
$modalPadding: (small: 3.5rem 1.5rem 1.5rem 1.5rem, medium: 3rem);
$modalCloseButtonFontFamily: 'swa-icon';
$modalCloseButtonFontSize: (small: 1.5rem);
$modalCloseButtonColor: $brandNeutralDarkColor;
$modalCloseButtonAfterContent: '\e011';

/* Merchant Tile */
$merchantTileItemPositionTop: (small: .2rem);
$merchantTileItemPositionBottom: (small: .2rem);
$merchantTileItemPositionLeft: (small: .2rem);
$merchantTileItemPositionRight: (small: .2rem);
$merchantTileWidth: 12rem;
$merchantTileHeight: 13.3rem;
$merchantTileImageMargin: (small: 2rem auto 0);
$merchantTileFavoriteIconWidth: (small: 1.8rem, medium: 1.8rem);
$merchantTileFavoriteIconHeight: (small: 1.8rem, medium: 1.8rem);
$merchantTileRebatePadding: .7rem 0 0 0;

/* Rebate */
$rebateColor: $brandTextColor;
$rebateValueColor: $brandPrimaryColor;
$rebateCurrencyColor: $brandPrimaryColor;
$rebateElevationColor: $brandTextColor;
$rebateElevationStrikethroughPrimary: 1px solid #000;
$rebateCurrencyFontWeight: bold;
$rebateValueWithCurrencyFontWeight: bold;

/* Premier Rebate */
$premierRebateNewValueFontSize: 1.4rem;
$premierRebateValueWithCurrencyFontSize: 1.4rem;
$premierRebateValueWithCurrencyOldValueFontSize: 1.2rem;
$premierRebateValueFontSize: 1.4rem;
$premierRebateOldValueAfterContent: '';
$premierRebatePrefixTieredFirstLetterTextTransform: ();

/* Lightbox */
$lightboxCloseIconContent: '\E011';
$lightboxCloseIconFontFamily: 'swa-icon';
$lightboxCloseFontSize: (small: 1.5rem, medium: 1.5rem);
$lightboxCloseColor: (small: $brandNeutralDarkColor, medium: $brandNeutralDarkColor);
$lightboxOverlayBackground: (small: rgba(28, 28, 28, .8), medium: rgba(0, 0, 0, .6));

/* Merchant Tile */
$merchantTileBorder: (small: 1px solid $brandBorderColor, medium: 1px solid $brandBorderColor);
$merchantTileBoxShadow: (small: 0 2px 4px 0 rgba(255, 255, 255, .5), medium: 0 2px 4px 0 rgba(255, 255, 255, .5));
$merchantTileBorderRadius: 0;
$merchantTilePillWidth: (small: 8.8rem, medium: 8.8rem);
$merchantTilePillBottom: 5rem;
$merchantTileRebateBackground: (small: #f5f5f5, medium: #f5f5f5);

/* Merchant offer Modal (Offer overlay) */
$merchantOverlayModalCloseButtonColor: (small: #fff, medium: $brandNeutralDarkColor);
$merchantOverlayContentPadding: (small: 0, medium: 0 1rem 1rem);
$merchantOverlayHeadLogoPadding: (small: 1.5rem 2rem, medium: 2.6rem 2.4rem 0);
$merchantOverlayHeadLogoOffsetTop: (small: 2.7rem, medium: -4.8rem);
$merchantOverlayHeadLogoBorderRadius: (small: 0, medium: 0);
$merchantOverlayFavoriteIconPositionRight: (small: .5rem, medium: .7rem);
$merchantOverlayFavoriteIconPositionTop: (small: .5rem, medium: .7rem);
$merchantOverlayFavoriteIconWidth: (small: 1.3rem, medium: 1.7rem);
$merchantOverlayFavoriteIconHeight: (small: 1.3rem, medium: 1.7rem);
$merchantOverlayOfferTitleFont: (small: 700 2rem/2.6rem $brandFontFamily, medium: 700 2.5rem/2.8rem $brandFontFamily);
$merchantOverlayDescriptionMargin: (small: 0, medium: 5.4rem 0 0);
$merchantOverlayOfferTitleMargin: (small: 0, medium: 0);
$merchantOverlayOfferTitleColor: (small: $brandNeutralDarkColor, medium: $brandNeutralDarkColor);
$merchantOverlayHeadOfferExpiresColor: (small: $brandTextColor2, medium: $brandNeutralDarkColor);
$merchantOverlayHeadOfferExpiresMargin: (small: 1rem 0 2rem, medium: 2rem 0 1rem);
$merchantOverlayHeadOfferExpiresFontSize: (small: 1.2rem, medium: 1.4rem);
$merchantOverlayHeadOfferSeparatorBorderTop: (small: none, medium: 1px solid #e7ecef);
$merchantOverlayHeadOfferSeparatorMargin: (small: (), medium: 2rem 0 0);
$merchantOverlayRebateOldValueFontSize: (small: 1.2rem, medium: 1.8rem);
$merchantOverlayRebateNewValueFontSize: (small: 1.4rem, medium: 1.8rem);
$merchantOverlayRebateValueWithCurrencyFontSize: (small: 1.4rem, medium: 1.8rem);
$merchantOverlayRebatePrefixFontSize: (small: 1.4rem, medium: 1.8rem);
$merchantOverlayRebatePrefixColor: (small: $rebateValueColor, medium: $rebateValueColor);
$merchantOverlayRebatePrefixFontWeight: (small: bold, medium: bold);
$merchantOverlayCouponControlsJustifyContent: center;
$merchantOverlayCouponCodeInputMinWidht: (small: 22rem, medium: 0);
$merchantOverlayCouponCodeInputMaxWidht: (small: 22rem, medium: 16.3rem);
$merchantOverlayCouponCodeCopyWidth: (small: 8rem, medium: 12rem);
$merchantOverlayCouponCodeInputHeight: (small: 4.8rem, medium: 3rem);
$merchantOverlayCouponCodeInputBorder: (small: none, medium: none);
$merchantOverlayCouponCodeInputBorderRadius: (small: 0, medium: 3px 0 0 3px);
$merchantOverlayCouponCodeCopyBorderRadius: (small: 0, medium: 0 3px 3px 0);
$merchantOverlayCouponCodeInputBackgroundColor: (small: #f5f5f5, medium: #e6e7e8);
$merchantOverlayCouponCodeInputLetterSpacing: 0;
$merchantOverlayCouponCodeInputColor: (small: $brandTextColor, medium: $brandTextColor);
$merchantOverlayCouponCodeInputFont: (small: 700 1.2rem/2rem $brandFontFamily, medium: 700 1.2rem/3rem $brandFontFamily);
$merchantOverlayCouponCodeInputTextAlign: (small: left, medium: center);
$merchantOverlayCouponCodeCopyBorder: (small: 1px solid #f5f5f5, medium: 1px solid #e6e7e8);
$merchantOverlayCouponCodeCopyColor: (small: $brandPrimaryColor, medium: $brandTextColor);
$merchantOverlayCouponCodeCopyFont: (small: 700 1.2rem/2rem $brandFontFamily, medium: 700 1.2rem/3rem $brandFontFamily);
$merchantOverlayCouponCodeCopyTextTransform: (small: none, medium: uppercase);
$merchantOverlayCouponCodeCopyHoverBackground: rgba(17, 27, 64, .25);
$merchantOverlayCouponCodeCopyHoverColor: $brandWhite;
$merchantOverlayCouponCodeIsCopiedBackground: rgba(17, 27, 64, .25);
$merchantOverlayCouponCodeIsCopiedColor: $brandWhite;
$merchantOverlayCouponIntroFontSize: (small: 1.4rem, medium: 1.2rem);
$merchantOverlayCouponIntroMargin: (small: 1rem 0 2rem, medium: 1rem 0);
$merchantOverlayCouponIntroColor: (small: $brandTextColor2, medium: $brandTextColor);
$merchantOverlayShopNowMargin: (small: 2rem 0, medium: 2rem 0);
$merchantOverlayShopNowFont: (small: 700 1.7rem/2.4rem $brandFontFamily, medium: 700 1.4rem/1.6rem $brandFontFamily);
$merchantOverlayShopNowMinWidth: (small: 25.8rem, medium: 20rem);
$merchantOverlayShopNowBackgroundColor: $brandButtonBackgroundColor;
$merchantOverlayShopNowBorder: none;
$merchantOverlayShopNowPadding: (small: 1.2rem 0, medium: 1.2rem 0);
$merchantOverlayShopNowColor: $brandNeutralDarkColor;
$merchantOverlayShopNowBorderRadius: 2px;
$merchantOverlayFooterMerchantTermsColor: (small: $brandTextColor2, medium: #8f8f8f);
$merchantOverlayFooterTermsFontSize: (small: 1.2rem, medium: .9rem);
$merchantOverlayFooterTermsTextDecoration: none;
$merchantOverlayDecorationDisplay: (small: block, medium: none);
$merchantOverlayDecorationHeight: 146px;
$merchantOverlayDecorationBackground: #294299;
$merchantOverlayDecorationPosition: relative;
$merchantOverlayDecorationAfterContent: '';
$merchantOverlayDecorationAfterDisplay: block;
$merchantOverlayDecorationAfterWidth: 100%;
$merchantOverlayDecorationAfterHeight: 56px;
$merchantOverlayDecorationAfterBackground: $brandSecondaryColor;
$merchantOverlayDecorationAfterPosition: absolute;
$merchantOverlayDecorationAfterPositionBottom: 0;
$merchantOverlayDecorationMargin: 0;
$merchantOverlayDescriptionDisplay: (small: flex, medium: block);
$merchantOverlayDescriptionFlexDirection: (small: column, medium: initial);
$merchantOverlayDisclaimerFontSize: (small: 1.2rem, medium: 1.4rem);
$merchantOverlayDisclaimerColor: (small: #566f80, medium: #111b40);
$merchantOverlayDisclaimerMargin: 1rem auto 0;
$merchantOverlayDisclaimerWidth: ();
$merchantOverlayDisclaimerOrder: (small: 3, medium: ());
$merchantOverlayOfferTitleOrder: (small: 2, medium: ());
$merchantOverlayHeadOfferExpiresOrder:  (small: 4, medium: ());
$merchantOverlayPremierMerchantRebateOrder: (small: 1, medium: ());
$merchantOverlayHeadOfferSeparatorDisplay: (small: none, medium: block);
$merchantOverlayHeadRebatePadding: (small: 0 0 2rem, medium: 0);
$merchantOverlayHeadRebateBorderBottom: (small: 1px solid rgba(0, 0, 0, .1), medium: none);
$merchantOverlayOfferTitleLetterSpacing: (small: -.5px, medium: 0);
$merchantOverlayCouponCodeInputPadding: (small: .2rem 1.5rem, medium: .2rem 0 .2rem 1rem);
$merchantOverlayShopNowLetterSpacing: (small: -.5px, medium: 0);
$merchantOverlayFooterMerchantTermsMargin: (small: 0, medium: 1rem 0 0);
$merchantOverlayExtraPillWidth: 12rem;

/* Pill */
$pillBackground: $brandPrimaryColor;
$pillLabelPadding: 0 0 0 2rem;
$pillLabelAfterBackground: url('../assets/images/icons/extra.svg') no-repeat no-repeat;
$pillLabelAfterWidth: .8rem;
$pillLabelAfterHeight: .8rem;
$pillLabelAfterPositionRight: 7rem;

/* New Pill */
$newPillLabelPadding: 0;
$newPillLabelAfterDisplay: none;

/* RebateV2 */
$rebateV2ValueColor: $brandPrimaryColor;
$rebateV2OldValueColor: $brandTextColor;
$rebateV2OldValueLineHeight: 1.8rem;
$rebateV2NewValueFontWeight: bold;
$rebateV2NewValueLetterSpacing: 0;
$rebateV2NewValueLineHeight: 2rem;

/* Letterbox */
$heroCarouselContainerBorder: (smallMobile: (), mediumMobile: (), smallTablet: 1px solid #d8d8d8,
largeMobileAndSmallDesktop: 1px solid #d8d8d8, largeTabletAndXLargeDesktop: 1px solid #d8d8d8);
$heroCarouselMargin: (smallMobile: 0 0 3rem 0, mediumMobile: 0 0 3rem 0, smallTablet: 0 0 3rem 0,
largeMobileAndSmallDesktop: 0 0 3rem 0, largeTabletAndXLargeDesktop: 0 0 3rem 0);
$heroCarouselContainerMargin: (smallMobile: 0 0 3.7rem);
$heroCarouselBreakPointLeftImageWidths: (smallMobile: 420px, mediumMobile: 420px, smallTablet: 372px,
largeMobileAndSmallDesktop: 333px, largeTabletAndXLargeDesktop: 420px);
$heroCarouselBreakPointHeights: (smallMobile: auto, mediumMobile: auto, smallTablet: 261px,
largeMobileAndSmallDesktop: 234px, largeTabletAndXLargeDesktop: 295px);
$heroCarouselTileWithExtraPillPadding: 0;
$heroCarouselBorderRadius: (smallMobile: 0, mediumMobile: 0, smallTablet: 0,
largeMobileAndSmallDesktop: 0, largeTabletAndXLargeDesktop: 0);
$heroCarouselBorder: (smallMobile: .9px solid #e6e7e8, mediumMobile: .9px solid #e6e7e8, smallTablet: (),
largeMobileAndSmallDesktop: (), largeTabletAndXLargeDesktop: ());
$heroCarouselBreakPointOfferBottomDetailsContentMinHeights: (smallTablet: 10rem, largeMobileAndSmallDesktop: 10rem, largeTabletAndXLargeDesktop: 10rem);
$heroCarouselBreakPointOfferDetailsWidths: (smallMobile: (), mediumMobile: (), smallTablet: 374.85px,
largeMobileAndSmallDesktop: 331.86px, largeTabletAndXLargeDesktop: 475px);
$heroCarouselBreakPointOfferDetailsPadding: (smallMobile: .5rem 1rem .5rem 11.8rem, mediumMobile: .5rem 1rem .5rem 11.8rem, smallTablet: .5rem 4rem .3rem 1.7rem,
largeMobileAndSmallDesktop: .5rem 3rem .3rem 1.9rem, largeTabletAndXLargeDesktop: 2rem 3rem .3rem 4rem);
$heroCarouselBreakPointRebateFontSize: (smallMobile: 1.2rem, mediumMobile: 1.2rem, smallTablet: 1.6rem,
largeMobileAndSmallDesktop: 1.6rem, largeTabletAndXLargeDesktop: 1.6rem);
$heroCarouselBreakPointRebateMarginBottom: (smallMobile: (), mediumMobile: (), smallTablet: (),
largeMobileAndSmallDesktop: 1rem, largeTabletAndXLargeDesktop: ());
$heroCarouselInfobarMarketingHeaderMargin: (smallMobile: 2rem 0 1.5rem, mediumMobile: 2rem 0 1.5rem, smallTablet: (),
largeMobileAndSmallDesktop: (), largeTabletAndXLargeDesktop: ());
$heroCarouselBreakPointRebateColor: (smallMobile: (), mediumMobile: (), smallTablet: $brandPrimaryColor,
largeMobileAndSmallDesktop: $brandPrimaryColor, largeTabletAndXLargeDesktop: $brandPrimaryColor);
$heroCarouselBreakPointRebateFontWeight: (smallMobile: (), mediumMobile: (), smallTablet: 700,
largeMobileAndSmallDesktop: 700, largeTabletAndXLargeDesktop: 700);
$heroCarouselOldRebateElevationFontSize: (smallMobile: 1.2rem, mediumMobile: 1.2rem, smallTablet: 1.6rem,
largeMobileAndSmallDesktop: 1.6rem, largeTabletAndXLargeDesktop: 1.6rem);
$heroCarouselBreakPointRebateLineHeight: (smallMobile: 1.445rem, mediumMobile: 1.445rem, smallTablet: 2rem,
largeMobileAndSmallDesktop: 2.2rem, largeTabletAndXLargeDesktop: 2.7rem);
$heroCarouselBreakPointRebateNewValueFontSize: (smallMobile: 1.2rem, mediumMobile: 1.2rem, smallTablet: 1.6rem,
largeMobileAndSmallDesktop: 1.6rem, largeTabletAndXLargeDesktop: 1.6rem);
$heroCarouselBreakPointLongCopyFontSize: (smallMobile: 1.2rem, mediumMobile: 1.2rem, smallTablet: 1.6rem,
largeMobileAndSmallDesktop: 1.5rem, largeTabletAndXLargeDesktop: 1.8rem);
$heroCarouselBreakPointShortCopyFontSize: (smallMobile: 1.19rem, mediumMobile: 1.19rem, smallTablet: 1.6rem,
largeMobileAndSmallDesktop: 1.5rem, largeTabletAndXLargeDesktop: 1.8rem);
$heroCarouselBreakPointLongCopyMarginTop: (smallMobile: .5rem, mediumMobile: .5rem, smallTablet: .6rem,
largeMobileAndSmallDesktop: 1rem, largeTabletAndXLargeDesktop: .6rem);
$heroCarouselBreakPointLongCopyLineHeight: (smallMobile: 1.7rem, mediumMobile: 1.7rem, smallTablet: 2.6rem,
largeMobileAndSmallDesktop: 2.2rem, largeTabletAndXLargeDesktop: 2.8rem);
$heroCarouselCopyColor: (smallMobile: $brandGray, mediumMobile: $brandGray, smallTablet: $brandNeutralDarkColor,
largeMobileAndSmallDesktop: $brandNeutralDarkColor, largeTabletAndXLargeDesktop: $brandNeutralDarkColor);
$heroCarouselButtonWidth: (smallMobile: 12.5rem, mediumMobile: 12.5rem, smallTablet: 20rem,
largeMobileAndSmallDesktop: 20rem, largeTabletAndXLargeDesktop: 20rem);
$heroCarouselButtonFontSize: (smallMobile: 1.6rem, mediumMobile: 1.6rem, smallTablet: 1.6rem,
largeMobileAndSmallDesktop: 1.6rem, largeTabletAndXLargeDesktop: 1.6rem);
$heroCarouselButtonPadding: (smallMobile: 1rem .5rem, mediumMobile: 1rem .5rem, smallTablet: 1rem,
largeMobileAndSmallDesktop: 1rem, largeTabletAndXLargeDesktop: 1rem);
$heroCarouselButtonColor: (smallMobile: (), mediumMobile: (), smallTablet: $brandNeutralDarkColor,
largeMobileAndSmallDesktop: $brandNeutralDarkColor, largeTabletAndXLargeDesktop: $brandNeutralDarkColor);
$heroCarouselButtonMarginTop: (smallMobile: 0, mediumMobile: 0, smallTablet: 1.5rem,
largeMobileAndSmallDesktop: 0, largeTabletAndXLargeDesktop: 1.5rem);
$heroCarouselCouponCodeColor: (smallMobile: #1c7bd9, mediumMobile: #1c7bd9, smallTablet: #0076a5,
largeMobileAndSmallDesktop: #0076a5, largeTabletAndXLargeDesktop: #0076a5);
$heroCarouselBreakPointCouponCodeMargin: (smallMobile: .5rem 0 0, mediumMobile: .5rem 0 0, smallTablet: 1.5rem 0,
largeMobileAndSmallDesktop: 1.5rem 0, largeTabletAndXLargeDesktop: 1.5rem 0 0);
$heroCarouselCouponCodeTextLetterSpacing: (smallMobile: 0, mediumMobile: 0, smallTablet: .5px,
largeMobileAndSmallDesktop: .5px, largeTabletAndXLargeDesktop: .5px);
$heroCarouselCouponCodeTextLineHeight: 1.7rem;
$heroCarouselCouponCodeTextFontSize: (smallMobile: 1.2rem, mediumMobile: 1.2rem, smallTablet: 1.5rem,
largeMobileAndSmallDesktop: 1.3rem, largeTabletAndXLargeDesktop: 1.6rem);
$heroCarouselBreakPointCouponCodeFontSize: (smallMobile: 1.2rem, mediumMobile: 1.2rem, smallTablet: 1.5rem,
largeMobileAndSmallDesktop: 1.3rem, largeTabletAndXLargeDesktop: 1.6rem);
$heroCarouselCouponCodeLabelColor: (smallMobile: $brandTextColor, mediumMobile: $brandTextColor, smallTablet: $brandTextColor,
largeMobileAndSmallDesktop: $brandTextColor, largeTabletAndXLargeDesktop: $brandTextColor);
$heroCarouselDisclaimerFontSize: (smallMobile: 1rem, mediumMobile: 1rem, smallTablet: .8rem,
largeMobileAndSmallDesktop: .8rem, largeTabletAndXLargeDesktop: .8rem);
$heroCarouselDisclaimerLineHeight: 1.2rem;
$heroCarouselDisclaimerColor: (smallMobile: $brandTextColor2, mediumMobile: $brandTextColor2, smallTablet: #8f8f8f,
largeMobileAndSmallDesktop: #8f8f8f, largeTabletAndXLargeDesktop: #8f8f8f);
$heroCarouselInfobarMarketingHeaderLetterSpacing: (smallMobile: -.41px, mediumMobile: -.41px, smallTablet: -.5px,
largeMobileAndSmallDesktop: -.5px, largeTabletAndXLargeDesktop: -.5px);
$heroCarouselInfobarMarketingHeaderColor: (smallMobile: $brandNeutralDarkColor, mediumMobile: $brandNeutralDarkColor, smallTablet: $brandNeutralDarkColor,
largeMobileAndSmallDesktop: $brandNeutralDarkColor, largeTabletAndXLargeDesktop: $brandNeutralDarkColor);
$heroCarouselInfobarMarketingTextFontSize: (smallMobile: 1.6rem, mediumMobile: 1.6rem, smallTablet: 1.8rem,
largeMobileAndSmallDesktop: 1.6rem, largeTabletAndXLargeDesktop: 2rem);
$heroCarouselInfobarMarketingTextLineHeight: (smallMobile: 2.8rem, mediumMobile: 2.8rem, smallTablet: 2.8rem,
largeMobileAndSmallDesktop: 2.6rem, largeTabletAndXLargeDesktop: 3.2rem);
$heroCarouselInfobarMarketingTextColor: (smallMobile: $brandTextColor, mediumMobile: $brandTextColor, smallTablet: $brandTextColor,
largeMobileAndSmallDesktop: $brandTextColor, largeTabletAndXLargeDesktop: $brandTextColor);
$heroCarouselInfobarMarketingCTAMargin: (smallMobile: 0 0 2.1rem, mediumMobile: 0 0 2.1rem, smallTablet: 0 0 1.2rem,
largeMobileAndSmallDesktop: 0 0 1.2rem, largeTabletAndXLargeDesktop: 0 0 1.2rem);
$heroCarouselInfobarMarketingCTAPadding: (smallMobile: 1rem 5.2rem, mediumMobile: 1rem 5.2rem, smallTablet: 1rem 5.2rem,
largeMobileAndSmallDesktop: 1rem 5.2rem, largeTabletAndXLargeDesktop: 1rem 5.2rem);
$heroCarouselInfobarMarketingCTABackground: $brandYellow;
$heroCarouselInfobarMarketingCTABoxShadow: none;
$heroCarouselInfobarMarketingCTAColor: $brandNeutralDarkColor;
$heroCarouselInfobarMarketingCTAFontSize: (smallMobile: 1.5rem, mediumMobile: 1.5rem, smallTablet: 1.5rem,
largeMobileAndSmallDesktop: 1.5rem, largeTabletAndXLargeDesktop: 1.5rem);
$heroCarouselInfobarMarketingCTALetterSpacing: -.41px;
$heroCarouselOfferDetailsMinHeight: 10rem;
$heroCarouselOfferDetailsTopMargin: .5rem 0 0;
$heroCarouselBreakPointLogoPositionTop: (smallMobile: -0, mediumMobile: 0);
$heroCarouselBreakPointLogoPositionLeft: (smallMobile: -10.8rem, mediumMobile: -10.8rem);
$heroCarouselInfobarMarketingCountdownBackgrond: (smallMobile: $brandNeutralMediumColor, mediumMobile: $brandNeutralMediumColor, smallTablet: $brandNeutralMediumColor,
largeMobileAndSmallDesktop: $brandNeutralMediumColor, largeTabletAndXLargeDesktop: $brandNeutralMediumColor);
$heroCarouselInfobarMarketingHeaderBorderRadius: (smallMobile: 0, mediumMobile: 0, smallTablet: 0,
largeMobileAndSmallDesktop: 0, largeTabletAndXLargeDesktop: 0);
$heroCarouselInfobarMarketingHeaderPadding: (smallMobile: 2.2rem .5rem, mediumMobile: 2.2rem .5rem, smallTablet: 1.4rem .5rem,
largeMobileAndSmallDesktop: 1rem .5rem, largeTabletAndXLargeDesktop: 1.4rem .5rem);
$heroCarouselInfobarMarketingCountdownColor: $brandNeutralDarkColor;
$heroCarouselInfobarMarketingCountdownLineHeight: (smallMobile: 1.2rem, mediumMobile: 1.2rem, smallTablet: 1.2rem,
largeMobileAndSmallDesktop: 1.2rem, largeTabletAndXLargeDesktop: 1.2rem);
$heroCarouselInfobarMarketingCountdownCounterColor: $brandNeutralDarkColor;
$heroCarouselInfobarMarketingCountdownLetterSpacing: (smallMobile: 0, mediumMobile: 0, smallTablet: 0,
largeMobileAndSmallDesktop: 0, largeTabletAndXLargeDesktop: 0);
$heroCarouselInfobarMarketingCountdownCounterBeforeMargin: 0 .6rem;
$heroCarouselInfobarMarketingCountdownCounterBorderRight: 1px solid rgba(17, 27, 64, .3);
$heroCarouselInfobarMarketingCountdownCounterFontSize: (smallMobile: 1.8rem, mediumMobile: 1.8rem, smallTablet: 1.8rem,
largeMobileAndSmallDesktop: 1.6rem, largeTabletAndXLargeDesktop: 1.6rem);
$heroCarouselInfobarMarketingCountdownCounterBeforeColor: rgba(17, 27, 64, .3);
$heroCarouselInfobarMarketingCountdownCounterAfterFontSize: (smallMobile: 1.7rem, mediumMobile: 1.7rem, smallTablet: 1.4rem,
largeMobileAndSmallDesktop: 1.2rem, largeTabletAndXLargeDesktop: 1.2rem);
$heroCarouselInfobarMarketingCountdownPrefixTextPadding: (smallMobile: 0 .5rem 0 0, mediumMobile: 0 .5rem 0 0, smallTablet: 0 .5rem 0 0,
largeMobileAndSmallDesktop: 0 .5rem 0 0, largeTabletAndXLargeDesktop: 0 .5rem 0 0);
$heroCarouselInfobarMarketingCountdownPrefixTextFontSize: (smallMobile: 1.7rem, mediumMobile: 1.7rem, smallTablet: 1.4rem,
largeMobileAndSmallDesktop: 1.2rem, largeTabletAndXLargeDesktop: 1.2rem);
$heroCarouselInfoBarMarketingContentPadding: (smallMobile: 0, mediumMobile: 0, smallTablet: .5rem,
largeMobileAndSmallDesktop: .5rem, largeTabletAndXLargeDesktop: .5rem);
$heroCarouselInfobarMarketingHeaderFontSize: (smallMobile: 2.8rem, mediumMobile: 2.8rem, smallTablet: 2.8rem,
largeMobileAndSmallDesktop: 2.8rem, largeTabletAndXLargeDesktop: 3.5rem);
$heroCarouselInfobarMarketingHeaderLineHeight: (smallMobile: 3.8rem, mediumMobile: 3.8rem, smallTablet: 3.2rem,
largeMobileAndSmallDesktop: 3.2rem, largeTabletAndXLargeDesktop: 3.2rem);
$heroCarouselExtraRewardsPillPositionLeft: (smallMobile: -10.8rem, mediumMobile: -10.8rem);
$heroCarouselInfobarMarketingTextPadding: (smallMobile: 0 2.5rem, mediumMobile: 0 2.5rem, smallTablet: 0 2.5rem,
largeMobileAndSmallDesktop: 0 2.5rem, largeTabletAndXLargeDesktop: 0 2.5rem);
$heroCarouselDisclaimerPadding: (smallMobile: 0 10rem 0 0, mediumMobile: 0 10rem 0 0, smallTablet: .1rem 1rem 0 0,
largeMobileAndSmallDesktop: 0 1rem 0 0, largeTabletAndXLargeDesktop: 0 1rem 0 0);
$heroCarouselExpireFontStyle: (smallMobile: italic, mediumMobile: italic, smallTablet: normal,
largeMobileAndSmallDesktop: normal, largeTabletAndXLargeDesktop: normal);
$heroCarouselArrowFontSize: 1.8rem;
$heroCarouselArrowColor: #ccc;

// Countdown
$countdownLettersFontWeight: bold;
$countdownLettersDaysContent: (small: 'D', medium: 'days', large: 'd');
$countdownLettersHoursContent: (small: 'H', medium: 'hours', large: 'h');
$countdownLettersMinutesContent: (small: 'M', medium: 'minutes', large: 'm');
$countdownLettersSecondsContent: (small: 'S', medium: 'seconds', large: 's');

/* Infobar Marketing */
$infoBarMarketingCountdownWrapBackground: $brandNeutralMediumColor;
$infoBarMarketingCountdownWrapColor: $brandNeutralDarkColor;
$infoBarMarketingCountdownWrapWidth: (small: calc(100% + 4rem));
$infoBarMarketingCountdownClockPrefixFontSize: 1.4rem;
$infoBarMarketingCountdownClockPrefixLetterSpacing: 0;
$infoBarMarketingCountdownClockPrefixFontWeight: 700;
$infoBarMarketingCountdownDigitColor: $brandNeutralDarkColor;
$infoBarMarketingCountdownDigitFontSize: 1.4rem;
$infoBarMarketingCountdownDigitBeforeDisplay: inline-block;
$infoBarMarketingCountdownDigitBeforeBorderRight: 1px solid rgba(17, 27, 64, .3);
$infoBarMarketingCountdownDigitBeforeHeight: 2.3rem;
$infoBarMarketingCountdownDigitBeforePadding: 0 .3rem 0 .5rem;
$infoBarMarketingCountdownDigitBeforeMargin: 0 .5rem -.5rem 0;
$infoBarMarketingHeaderLetterSpacing: -.5px;
$infoBarMarketingHeaderColor: $brandNeutralDarkColor;
$infoBarMarketingTextColor: $brandTextColor;
$infoBarMarketingButtonPadding: 1.2rem;
$infoBarMarketingButtonWidth: 26rem;
$infoBarMarketingButtonFontSize: 1.5rem;
$infoBarMarketingButtonLineHeight: 1;
$infoBarMarketingButtonColor: $brandNeutralDarkColor;

/* InfoBar Slider */
$infoBarSliderOfferWrapMargin: 1rem 1rem .8rem;
$infoBarSliderOfferBackground: $brandNeutralMediumColor;
$infoBarSliderOfferDOTWBackground: $brandSecondaryColor;
$infoBarSliderOfferDOTWLabelColor: $brandWhite;
$infoBarSliderOfferLabelColor: $brandNeutralDarkColor;
$infoBarSliderFavoriteIconRight: .7rem;
$infoBarSliderFavoriteIconTop: .7rem;
$infoBarSliderOfferLabelTextTransform: ();
$infoBarSliderOfferLabelLineHeight: 1.8rem;
$infoBarSliderOfferLabelTextAlign: center;
$infoBarSliderOfferLabelPadding: 1.2rem 2rem 0;
$infoBarSliderOfferRolloverCopyLineHeight: 2rem;
$infoBarSliderOfferWrapColor: $brandTextColor2;
$infoBarSliderOfferItemCopyTextAlign: center;
$infoBarSliderOfferRebateBackground: #f5f5f5;
$infoBarSliderOfferItemExpireLeft: 0;
$infoBarSliderOfferItemExpireDisplay: block;
$infoBarSliderOfferItemExpireWidth: 100%;
$infoBarSliderOfferItemExpireTextAlign: center;
$infoBarSliderOfferRebateOldValueColor: $rebateColor;
$infoBarSliderOfferRebateNewValueColor: $rebateValueColor;
$infoBarSliderOfferRebateHeight: 8.5rem;
$infoBarSliderOfferRebateBlockPadding: 2.5rem 1rem 1rem;
$infoBarSliderOfferWrapMinHeight: 34.6rem;
$infoBarSliderOfferWrapMinWidth: 23.9rem;
$infoBarSliderOfferItemExpireTop: 62%;
$infoBarSliderOfferRebateOldValuePadding: .3rem;
$infoBarSliderOfferCouponCodeWrapMargin: .5rem 0 0;
$infoBarSliderOfferCouponCodeWrapAlignItems: center;
$infoBarSliderOfferCouponCodeFontSize: 1.2rem;
$infoBarSliderOfferCouponCodeLetterSpacing: 0;
$infoBarSliderOfferCouponCodeLineHeight: 2rem;
$infoBarSliderOfferCouponCodeColor: #1c7bd9;

/* Infobar Accordion */
$infoBarAccordionHeight: (small: 9.2rem, large: 10rem);
$infoBarAccordionColor: #111b40;
$infoBarAccordionOfferTextFontSize: (small: 1.6rem, medium: 1.6rem, xxlarge: 1.8rem);
$infoBarAccordionOfferTextLineHeight: (small: 2.1rem, medium: 2.1rem, xxlarge: 2.1rem);
$infoBarAccordionOfferTextMargin: (small: 0 0 1rem 0, margin: 0 0 1rem 0, xxlarge: 0 0 1rem 0);
$infoBarAccordionOfferRebateFontSize: (small: 1.4rem);
$infoBarAccordionOfferRebateColor: #304cb2;
$infoBarAccordionOfferBoxShadow: inset 0 0 0 1px #d8d8d8;
$infoBarAccordionDotwLabelHeight: 2rem;
$infoBarAccordionDotwLabelWidth: 12rem;
$infoBarAccordionDotwLabelTop: -2rem;
$infoBarAccordionDotwLabelBackground: #a4baf2;
$infoBarAccordionDotwLabelColor: #111b40;
$infoBarAccordionDotwLabelBorderRadius: ();
$infoBarAccordionDotwLabelTextTransform: ();
$infoBarAccordionDotwLabelLetterSpacing: ();
$infoBarAccordionOfferCTABackground: #e6e7e8;
$infoBarAccordionOfferCTAColor: #111b40;
$infoBarAccordionOfferCTATextAfterContent: '\e005';
$infoBarAccordionOfferCTATextAfterFontFamily: 'swa-icon';
$infoBarAccordionOfferCTATextFontSize: 1.6rem;
$infoBarAccordionOfferCTATextFontWeight: bold;
$infoBarAccordionOfferCTATextAfterFontSize: 1.3rem;
$infoBarAccordionExpandIconBeforeContent: '+';
$infoBarAccordionExpandIconBeforeColor: #111b40;
$infoBarAccordionExpandIconBeforeFontSize: 2.6rem;
$infoBarAccordionExpandIconBeforeBackground: #e6e7e8;
$infoBarAccordionExpandIconBeforePadding: ();
$infoBarAccordionExpandIconBeforeDisplay: inline-block;
$infoBarAccordionExpandIconBeforeWidth: 1.8rem;
$infoBarAccordionExpandIconBeforeHeight: 1.8rem;
$infoBarAccordionExpandIconBeforeLineHeight: 2rem;
$infoBarAccordionExpandIconBeforeTextAlign: center;
$infoBarAccordionSecondOfferBackground: #1a2c80;
$infoBarAccordionSecondOfferCTABackground: #a4baf2;
$infoBarAccordionSecondOfferCTABoxShadow: ();
$infoBarAccordionSecondOfferCouponCodeAndRebateColor: #fff;
$infoBarAccordionSecondOfferCouponCodeColor: #fff;
$infoBarAccordionSecondOfferExpandIconBeforeBackground: #a4baf2;
$infoBarAccordionSecondOfferWrapColor: #fff;
$infoBarAccordionSecondOfferBorderLeft: 4px solid #304cb2;
$infoBarAccordionSecondOfferRebateColor: #fff;
$infoBarAccordionSecondOfferRebateValueColor: #fff;
$infoBarAccordionSecondOfferRebateOldValueColor: #fff;
$infoBarAccordionSecondOfferShortSummaryColor: #fff;
$infoBarAccordionSecondOfferBoxShadow: none;
$infoBarAccordionOfferShortSummaryColor: #111b40;
$infoBarAccordionOfferShortSummaryTextAlign: (medium: left, large: left, xxlarge: left);
$infoBarAccordionOfferShortSummaryFontSize: (small: 1.4rem, medium: 1.4rem, large: 1.4rem, xxlarge: 1.4rem);
$infoBarAccordionOfferShortSummaryFontWeight: (small: normal, medium: normal, large: normal, xxlarge: normal);
$infoBarAccordionOfferLogoPadding: (small: (), medium: (), large: (), xxlarge: ());
$infoBarAccordionOfferLogoBackground: (small: (), medium: (), large: (), xxlarge: ());
$infoBarAccordionOfferLogoBorderRadius: (small: (), medium: (), large: (), xxlarge: ());
$infoBarAccordionOfferCouponCodeAndRebateMargin: 0;
$infoBarAccordionOfferCouponCodeAndRebatePosition: relative;
$infoBarAccordionOfferCouponCodeAndRebateLineHeight: 1.6rem;
$infoBarAccordionOfferCouponCodeAndRebateMarginTop: .5rem;
$infoBarAccordionOfferCouponCodeAndRebateColor: #636363;
$infoBarAccordionOfferCouponCodeAndRebateDividerMargin: 0 1rem -.5rem 1rem;
$infoBarAccordionOfferCouponCodeAndRebateDividerFontWeight: normal;
$infoBarAccordionOfferCouponCodeAndRebateCodeColor: #0076a5;
$infoBarAccordionOfferCouponCodeAndRebateCodeFontWeight: bold;
$infoBarAccordionOfferShortSummaryRebateFontSize: (small: (), medium: 1.2rem, large: 1.2rem, xxlarge: 1.2rem);

/* Top Stores */
$topStoresExpandableBackground: $brandSecondaryColor;
$topStoresExpandableTextColor: $brandTextColorInverse;
$topStoresExpandableMerchantTileMerchantRebateV2Background: none;
$topStoresExpandableMerchantTileBorder: 2px solid $brandNeutralMediumColor;
$topStoresExpandableButtonBackground: transparent;
$topStoresExpandableButtonColor: $brandWhite;
$topStoresExpandableButtonBorder: 1px solid $brandWhite;
$topStoresExpandableButtonBoxShadow: 0 1px 0 0 #8f8f8f;
$topStoresMerchantTilePillPositionBottom: 5rem;
$topStoresMerchantTileRebateHeight: 6rem;
$topStoresMerchantTileRebateElevationOldFontSize: 1.1rem;
$topStoresMerchantTileRebateElevationOldLineHeight: 1.3rem;
$topStoresMerchantTileRebateElevationNewFontSize: 1.2rem;
$topStoresMerchantTileRebateElevationNewLineHeight: 1.3rem;
$topStoresPersonalizedMhpMerchantTileTitleColor: (small: (), large: $brandPrimaryColor);
$topStoresPersonalizedMhpMerchantTileViewAllLinkColor: (small: (), large: $brandPrimaryColor);

/* Featured Deals */
$featuredDealsButtonMargin: 5.9rem auto 0;
$featuredDealsButtonBorder: 1px solid $brandPrimaryColor;
$featuredDealsButtonColor: $brandPrimaryColor;
$featuredDealsButtonHoverColor: $featuredDealsButtonColor;
$featuredDealsButtonBackground: none;
$featuredDealsButtonBoxShadow: 0 1px 0 0 #8f8f8f;
$featuredDealMobileRoundedFavoriteIconWidth: 2rem;
$featuredDealMobileRoundedFavoriteIconHeight: 2rem;
$featuredDealMobileRoundedContentColor: $brandGray;
$featuredDealMobileRoundedCouponCodeColor: #1c7bd9;
$featuredDealDesktopColor: $brandTextColor2;
$featuredDealDesktopCouponCodeColor: #1c7bd9;

/* Sticky Footer Mobile */
$stickyFooterMobileContentBorderTop: ();
$stickyFooterMobileContentBorderBottom: 1px solid rgba(186, 197, 210, .25);
$stickyFooterMobileUseCodeFontSize: 1.2rem;
$stickyFooterMobileCodeFontSize: 1.2rem;
$stickyFooterMobileCodeLetterSpacing: 0;
$stickyFooterMobileShopNowAfterFontFamily: 'swa-icon';
$stickyFooterMobileShopNowAfterContent: '\e005';
$stickyFooterMobileShopNowAfterFontSize: 1.1rem;
$stickyFooterMobileShopNowFontSize: 1.3rem;
$stickyFooterMobileShopNowFontWeight: 600;
$stickyFooterMobileShopNowLineHeight: 1.9rem;
$stickyFooterMobileShopNowLetterSpacing: .3px;
$stickyFooterMobileMerchantRebatePrefixFontWeight: bold;
$stickyFooterMobileMerchantRebateLineHeight: 2rem;
$stickyFooterMobileImageWrapBorder: 1px solid #e6e7e8;

/* Sticky Footer Desktop */
$stickyFooterDesktopWrapBackground: #f5f5f5;
$stickyFooterDesktopContentColor: ();
$stickyFooterDesktopControlColor: #111b40;
$stickyFooterDesktopControlBackground: #d8d8d8;
$stickyFooterDesktopControlBoxShadow: ();
$stickyFooterDesktopControlBeforeContent: '\e010';
$stickyFooterDesktopControlBeforeFontFamily: 'swa-icon';
$stickyFooterDesktopControlBeforeFontSize: 1.6rem;
$stickyFooterDesktopControlPositionTop: -3.2rem;
$stickyFooterDesktopControlBeforeCollapsedContent: '\e044';
$stickyFooterDesktopControlBeforeCollapsedFontSize: 1rem;
$stickyFooterDesktopControlCollapsedBackground: #ffbf27;
$stickyFooterDesktopButtonBorder: none;
$stickyFooterDesktopButtonFontSize: (medium: 1.6rem, xlarge: 1.6rem);
$stickyFooterDesktopButtonPadding: .6rem;
$stickyFooterDesktopRolloverCopyAndDateColor: #111b40;
$stickyFooterDesktopMerchantRebatePrefixColor: $brandPrimaryColor;
$stickyFooterDesktopMerchantRebateOldValueFontSize: 1.4rem;
$stickyFooterDesktopMerchantRebateFontWeight: normal;
$stickyFooterDesktopMerchantRebateOldValueColor: #8f8f8f;
$stickyFooterDesktopMerchantRebateOldValueBorderTop: 1px solid #111b40;
$stickyFooterDesktopUseCodeColor: #3e63c0;
$stickyFooterDesktopCodeFontWeight: ();
$stickyFooterDesktopDisclaimerColor: $brandNeutralDarkColor2;

/* Quick Links Tray */
$quickLinksTrayFilter: drop-shadow(0 1px 6px rgba(0, 0, 0, .75));
$quickLinksTrayBackgroundColor: url('~org/assets/images/quick_tray_back_swoosh.png');
$quickLinksTrayLinkPadding: 2rem .5rem;
$quickLinksTrayLinkLetterMargin: 0;
$quickLinksTrayLinkTextTransform: uppercase;
$quickLinksTrayLinkFontSize: 1rem;
$quickLinksSearchIconBeforeFontFamily: 'swa-icon';
$quickLinksSearchIconBeforeContent: '\E029';
$quickLinksTrayIconAccountUrl: url('~org/assets/images/icons/account.svg');
$quickLinksTrayIconExtraRewardsUrl: url('~org/assets/images/icons/extra.svg');
$quickLinksTrayIconAllStoresUrl: url('~org/assets/images/icons/all_stores.svg');
$quickLinksTrayIconHeight: 1.8rem;
$quickLinksTrayIconAllStoresHeight: 2rem;
$quickLinksTrayIconAllStoresMarginBottom: .5rem;
$quickLinksTraySecondLinkBorder: ();
$quickLinksTrayToggleFontSize: 1.2rem;
$quickLinksTrayToggleAfterContent: '\E005';
$quickLinksTrayToggleAfterFontFamily: 'swa-icon';
$quickLinksTrayToggleAfterFontSize: .8rem;

/* Search */
$searchFormInputColor: $brandWhite;
$searchFormInputFontSize: 1.8rem;
$searchFormInputFontWeight: ();
$searchFormInputPadding: .4rem 1.5rem .4rem 4rem;
$searchFormInputHeight: 3rem;
$searchFormInputLineHeight: 3rem;
$searchFormInputBorder: 1px solid #fff;
$searchFormInputBorderWidth: 0 0 1px 0;
$searchFormSubmitButtonHeight: 3rem;
$searchFormSubmitButtonLineHeight: 3rem;
$searchFormSubmitButtonWidth: 3rem;
$searchFormMargin: 2rem 0 0;
$searchFormSubmitButtonRight: ();
$searchFormSubmitButtonLeft: 0;
$searchFormSubmitButtonTop: .2rem;
$searchFormSubmitButtonColor: $brandWhite;
$searchFormFocusPlaceholderColor: transparent;
$searchFormFocusBackground: #1a2c80;
$searchFormDesktopInputFontSize: 1.8rem;
$searchFormDesktopInputHeight: 3rem;
$searchFormDesktopInputPadding: .4rem .4rem .4rem 4rem;
$searchFormDesktopSubmitPositionTop: .4rem;
$searchFormDesktopSubmitPositionLeft: 1rem;
$searchFormDesktopInputBorderBottom: ();
$searchFormDesktopDropDownPositionLeft: -7rem;
$searchFormDesktopHoverOutline: none;
$searchFormDesktopHoverBoxShadow: 0 2px 1px -1px $brandGrayLight;
$searchFormDesktopInputCursor: pointer;
$searchFormFocusBorderColor: none;
$searchFormPosition: (small: absolute, medium: absolute, large: absolute);
$searchFormPositionLeft: (small: -2.5rem, medium: 0);
$searchFormPositionRight: (small: -2.5rem, medium: 0);
$searchFormPositionTop: ();
$searchFieldPlaceholderColor: $brandWhite;
$searchFieldPlaceholderTextTransform: uppercase;
$searchFieldPlaceholderFontWeight: 700;
$searchFieldPlaceholderFontSize: 1.5rem;
$searchFieldPlaceholderOpacity: 1;
$searchBarPadding: 1rem 1.5rem 2rem 1.5rem;
$searchBarDesktopBeforeDisplay: none;
$searchBarDesktopPadding: 0 1rem;
$searchBarDesktopBeforeDisplay: ();
$searchBarDesktopBeforeContent: '';
$searchBarDesktopBeforeWidth: 1px;
$searchBarDesktopBeforePosition: absolute;
$searchBarDesktopBeforeLeft: 0;
$searchBarDesktopBeforeHeight: 3rem;
$searchBarDesktopBeforeBackground: rgba(255, 255, 255, .3);

/* Explore Categories */
$exploreCategoriesSectionPadding: (small: 5rem 0 3.4rem);
$exploreCategoriesSectionBackground: (small: linear-gradient(180deg, $brandQuaternaryColor 0%, $brandPrimaryColor 100%));
$exploreCategoriesItemLinkBackground: $brandNeutralMediumColor;
$exploreCategoriesItemWidth: 86%;
$exploreCategoriesListGap: 3rem;
$exploreCategoriesItemLinkColor: $brandNeutralDarkColor;
$exploreCategoriesItemLinkFontSize: 1.4rem;
$exploreCategoriesItemLinkLineHeight: 1.6rem;
$exploreCategoriesIconBeforeFontFamily: 'swa_categories';
$exploreCategoriesIconBeforeColor: $brandSecondaryColor;
$exploreCategoriesIconBeforeDepartmentStoresContent: '\e805';
$exploreCategoriesIconBeforeComputersElectronicContent: '\e821';
$exploreCategoriesIconBeforeWomensClothingContent: '\e81c';
$exploreCategoriesIconBeforeMensClothingContent: '\e812';
$exploreCategoriesIconBeforeBeautyScincareContent: '\e820';
$exploreCategoriesIconBeforeShoesContent: '\e815';
$exploreCategoriesIconBeforeSportsOutdoorsContent: '\e818';
$exploreCategoriesIconBeforeFlowersGiftsContent: '\e808';
$exploreCategoriesIconBeforeBabiesKidsToysContent: '\e801';
$exploreCategoriesIconBeforeHomeKitchenContent: '\e80f';
$exploreCategoriesIconBeforeHealthWellnessContent: '\e80e';
$exploreCategoriesIconBeforeToolsAutoPetsContent: '\e819';

/* Earning Snapshot */
$earningsSnapshotSectionBackground: (small: $brandNeutralDarkColor);
$earningsSnapshotSummaryFontWeight: normal;
$earningsSnapshotSummaryLineHeight: 2.5rem;
$earningsSnapshotSummaryMargin: 0 1rem 3rem;
$earningsSnapshotSummaryTextAlign: center;
$earningsSnapshotTransactionColor: $brandTertiaryColor;
$earningsSnapshotTransactionBorder: 1px solid $brandGrayLight;
$earningsSnapshotTransactionBorderRadius: ();
$earningsSnapshotTransactionMargin: 0 0 1rem;
$earningsSnapshotTransactionPadding: 1rem 2.5rem;
$earningsSnapshotTransactionMerchantColor: $brandNeutralDarkColor;
$earningsSnapshotTransactionMerchantFontWeight: bold;
$earningsSnapshotTransactionMerchantTextTransform: uppercase;
$earningsSnapshotTransactionMerchantFontSize: 1.5rem;
$earningsSnapshotTransactionMerchantLineHeight: 2.3rem;
$earningsSnapshotTransactionInfoFontSize: 1.4rem;
$earningsSnapshotTransactionInfoTopFontWeight: 300;
$earningsSnapshotTransactionInfoTopLineHeight: 2.3rem;
$earningsSnapshotTransactionInfoBottomLineHeight: 2.3rem;
$earningsSnapshotTransactionInfoBottomRewardFontWeight: bold;
$earningsSnapshotButtonBorder: 1px solid $brandWhite;
$earningsSnapshotButtonColor: $brandWhite;
$earningsSnapshotButtonBoxShadow: 0 1px 0 0 #8f8f8f;

/* Popular (Trending) Offers */
$popularOfferBackground: $brandWhite;
$popularOfferBorder: (small: 1px solid #e6e7e8);
$popularOffersBackground: (small: $brandNeutralDarkColor, medium: none);
$popularOffersSectionTitleH2Color: (small: $brandWhite, medium: $brandPrimaryColor);
$popularOfferImageBorder: (small: ());
$popularOfferImageBorderRadius: 0;
$popularOfferRebateBorder: (small: none);
$popularOfferRebateBorderRadius: 0;
$popularOfferRebateColor: $rebateValueColor;
$popularOfferRebateFontWeight: (small: $rebateCurrencyFontWeight, medium: $rebateCurrencyFontWeight);
$popularOfferRebateValueAndPrefixColor: $rebateValueColor;
$popularOfferRebateValueAndPrefixFontWeight: (small: $rebateCurrencyFontWeight, medium: $rebateCurrencyFontWeight);
$popularOfferRebateBackground: (small: #f5f5f5);

/* EmailOptIn MHP */
$emailOptInWrapperSectionBackground: (small: #f5f5f5);
$emailOptInImageBackground: url('~org/assets/images/email.png') no-repeat 50% 0/contain;
$emailOptInImageWidth: 15.6rem;
$emailOptInImageHeight: 15.6rem;
$emailOptInTextLineHeight: 2.2rem;
$emailOptInSwitcherLabelTextFontSize: 1.4rem;
$emailOptInSwitcherLabelTextLineHeight: 2rem;
// Switcher
$switcherCheckedBackground: $brandPrimaryColor;

/* Email OptIn Success Modal */
$emailSuccessMessageColor: $brandNeutralDarkColor;
$emailSuccessMessageBackground: $brandYellow;
$emailSuccessMessageCloseButtonBeforeColor: $brandWhite;
$emailSuccessMessageCloseButtonBeforeFontFamily: 'swa-icon';
$emailSuccessMessageCloseButtonBeforeContent: '\e011';
$emailSuccessMessageCloseButtonBeforeFontSize: 1.5rem;
$emailSuccessMessageCloseButtonBeforeFontWeight: 700;
$emailSuccessMessageH2Margin: 0 0 .5rem;
$emailSuccessMessageH2Color: #4e4e4f;
$emailSuccessMessageOlayDescFontSize: 2rem;
$emailSuccessMessageOlayDescLineHeight: 1.4;
$emailSuccessModalBackground: $brandYellow;
$emailSuccessModalCloseButtonColor: $brandWhite;
$emailSuccessModalContentH2Color: #4e4e4f;
$emailSuccessModalContentH2FontSize: 3rem;
$emailSuccessModalContentH2FontWeight: 700;
$emailSuccessModalContentH2Margin: 0 0 .5rem;
$emailSuccessModalContentTextColor: $brandNeutralDarkColor;
$emailSuccessModalContentTextFontSize: 2rem;
$emailSuccessModalContentTextLineHeight: 1.4;
$emailSuccessMessageTextAlign: left;

/* Pick Favorites */
$pickFavoritesBackground: #f5f5f5;
$pickFavoritesMerchantLogoTileInnerWrapBoxShadow: 1px 1px 2px 0 rgba(0, 0, 0, .4);
$pickFavoritesButtonBackground: $brandYellow;
$pickFavoritesButtonColor: $brandNeutralDarkColor;
$pickFavoritesButtonBorder: none;
$pickFavoritesButtonBoxShadow: none;
$pickFavoritesHeadingDotDisplay: inline;

/* Marketing Merchants Home */
$marketingMerchantsHomeTileWidth: (small: 22.7rem, medium: 12rem);
$marketingMerchantsHomeTileHeight: (small: 23.7rem, medium: 13.3rem);
$marketingMerchantsHomeTileBorder: 1px solid $brandGrayLight;
$marketingMerchantsHomeTileBoxShadow: none;
$marketingMerchantsHomeViewAllLinkInsideSliderBackground: $brandNeutralMediumColor;
$marketingMerchantsHomeViewAllLinkInsideSliderColor: $brandNeutralDarkColor;
$marketingMerchantsHomeViewAllLinkInsideSliderFontSize: 1.8rem;
$marketingMerchantsHomeViewAllLinkInsideSliderFontWeight: bold;
$marketingMerchantsHomeViewAllLinkInsideSliderLineHeight: 2.6rem;
$marketingMerchantsHomeViewAllLinkHoverTextDecoration: none;
$marketingMerchantsHomeMerchantTileRebateHeight: 6rem;
$marketingMerchantsHomeMerchantTileRebateElevationOldFontSize: 1.1rem;
$marketingMerchantsHomeMerchantTileRebateElevationOldLineHeight: 1.3rem;
$marketingMerchantsHomeMerchantTileRebateElevationNewFontSize: 1.2rem;
$marketingMerchantsHomeMerchantTileRebateElevationNewLineHeight: 1.3rem;

/* Header Extra Rewards */
$headerExtraRewardsHeaderFontWeight: bold;
$headerExtraRewardsHeaderFontSize: 1.8rem;
$headerExtraRewardsHeaderColor: $brandNeutralDarkColor;
$headerExtraRewardsHeaderMargin: 0;
$headerExtraRewardsSectionTitlePadding: 2rem 2rem 1rem 3rem;
$headerExtraRewardsSectionTitleBorderBottom: 1px solid $brandGrayLight;
$headerExtraRewardsSectionTitleMargin: 0;
$headerExtraRewardsLinkBottomMargin: 0;
$headerExtraRewardsLinkBottomPadding: 2rem 2rem 2rem 3rem;
$headerExtraRewardsLinkBottomFontSize: 1.6rem;
$headerExtraRewardsLinkBottomDisplay: block;
$headerExtraRewardsRebateElevationOldValueFontSize: 1.4rem;

/* Navigation Banner */
$navBannerContentPadding: 1rem 2rem 1rem 8rem;
$navBannerTitleFontSize: 2rem;
$navBannerContentBackground: #a4baf2;
$navBannerContentPadding: 1rem 1rem 1rem 8rem;
$navBannerContentFontSize: 1.4rem;
$navBannerLinkColor: $brandNeutralDarkColor;
$navBannerLinkFontWeight: bold;
$navBannerLinkTextDecoration: underline;
$navBannerButtonBannerBackground: $brandWhite url('~org/assets/images/hiw_static_button.png') no-repeat center;
$navBannerButtonBannerImageBoxShadow: ();
$navBannerButtonBannerImageBorder: $brandGrayLight solid 1px;
$navBannerFavoritesBannerBackground: $brandWhite url('~org/assets/images/hiw_static_favorites.png') no-repeat center;

/* Header navigation */
$navigationListDropdownItemTriggerBeforeFontSize: 1.3rem;
$navigationListItemBorderBottom: 1px solid $brandGrayLight;
$navigationListDropdownItemTriggerBeforePositionLeft: ();
$navigationListDropdownItemTriggerBeforePositionRight: 2rem;
$navigationListDropdownItemTriggerBeforeContent: '\E03F';
$navigationListDropdownItemTriggerBeforeFontFamily: 'swa-icon';
$navigationListDropdownItemTriggerBeforeTransition: none;
$navigationListDropdownItemTriggerFocusedBeforeTransform: rotate(180deg);
$navigationListDropdownItemMenuMargin: ();
$navigationListDropdownItemMenuLinkHoverTextDecoration: underline;
$navigationListDropdownItemMenuLinkColor: $brandNeutralDarkColor;
$navigationListItemFontSize: 1.6rem;
$navigationListItemFocusedBackground: #f5f7f7;
$navigationListItemLinkColor: $brandNeutralDarkColor;
$navigationListItemHoverTextDecoration: underline;

$navCategoriesTOFLinkWidth: 22rem;
$navCategoriesTOFLinkMargin: 0 0 2rem 0;
$navCategoriesTOFDropdownMenuPositionLeft: 27.5rem;
$navCategoriesTOFDropdownMenuWidth: 36rem;
$navCategoriesTOFDropdownMenuBackground: #f5f7f7;
$navCategoriesTOFDropdownMenuPadding: 2rem 2rem 2rem 4rem;

$navCategoryFeaturedItemMargin: 0 -1rem ;
$navCategoryFeaturedItemPadding: 1.2rem 1rem;
$navCategoryFeaturedItemTitleColor: $brandNeutralDarkColor;
$navCategoryFeaturedItemElevationNewValueColor: $brandPrimaryColor;
$navCategoryFeaturedItemTitleFontSize: 1.4rem;
$navCategoryFeaturedFooterLinkFontSize: 1.6rem;
$navCategoryFeaturedFooterLinkColor: $brandPrimaryColor;
$navCategoryFeaturedItemBorderBottom: 1px solid $brandGrayLight;
$navCategoryFeaturedFooterLinkMargin: 1rem 0 0 0;
$navCategoryFeaturedFooterLinkCategoryTextTransform: lowercase;
$navCategoryFeaturedItemRebateFontSize: 1.4rem;
$navCategoryFeaturedItemRebateColor: $brandPrimaryColor;
$navCategoryFeaturedItemRebateFontWeight: bold;
$navCategoryFeaturedItemRebateOldValueFontSize: 1.2rem;
$navCategoryFeaturedItemRebateOldValueColor: $brandGray;
$navCategoryFeaturedItemHoverColorBackground: rgba(0, 0, 0, .05);

$categoryFlyoutTitleMargin: 0 0 1rem 0;
$categoryFlyoutTitleRebateOldValueDisplay: none;
$categoryFlyoutTitleRebateMargin: ();
$categoryFlyoutTitleRebatePadding: ();
$categoryFlyoutTitleRebateBorder: ();
$categoryFlyoutTitleRebateBackground:();
$categoryFlyoutTitleRebateFontSize: 1.6rem;
$categoryFlyoutTitleRebateTextAlign: ();
$categoryFlyoutMerchantTitleFontSize: 1.4rem;
$categoryFlyoutMerchantTitleMargin: 1rem 0 .5rem;
$categoryFlyoutTitleRebateNewValueFontSize: 1.6rem;

$navSeasonalFlyoutMargin: 2rem 0 0 0;
$navSeasonalFlyoutTileWidth: 13.8rem ;
$navSeasonalFlyoutTileHeight: 15.5rem;
$navSeasonalFlyoutCTAColor: ();
$navSeasonalFlyoutCTAFontSize: 1.6rem;
$navSeasonalFlyoutCTAWrapMargin: 1rem 0 0 0;
$navSeasonalFlyoutTileWrapperMargin: 0 0 1.2rem 0;
$navSeasonalFlyoutRebateElevationNewValueFontSize: 1.2rem;
$navSeasonalFlyoutRebateHeight: 7rem;

$navPopularStoresViewAllLinkColor: $brandPrimaryColor;
$navPopularStoresViewAllLinkFontSize: 1.6rem;
$navPopularStoresViewAllLinkMargin: 1rem 0 0 0;
$navPopularStoresViewAllLinkPadding: .9rem 1rem .8rem 1rem;

/* Quick Search */
$quickSearchMatchColor: $brandTertiaryColor;
$quickSearchMatchBackground: #f5f7f7;
$quickSearchMatchPadding: (small: 2rem 6rem 2rem 3rem, medium: 2rem 6rem 2rem 3rem);
$quickSearchMatchTextColor: ();
$quickSearchMatchTextFontSize: 1.3rem;
$quickSearchMerchantRebateFontWeight: bold;
$quickSearchMatchHighlightColor: $brandTertiaryColor;
$quickSearchMatchDashDisplay: none;
$quickSearchMatchTextMargin: 0 .5rem 0 0;
$quickSearchMatchFontSize: 1.4rem;
$quickSearchMatchLineHeight: 1.8rem;
$quickSearchMatchBorderBottom: 1px solid $brandGrayLight;
$quickSearchMatchHoverBackground: $brandGrayLight;
$quickSearchMerchantRebateColor: $brandPrimaryColor;
$quickSearchMerchantFavoriteIconPosition: (small: relative, medium: absolute);
$quickSearchMerchantFavoriteIconPositionLeft: (small: (), medium: ());
$quickSearchMerchantFavoriteIconPositionTop: (small: (), medium: 1.6rem);
$quickSearchMerchantFavoriteIconPositionRight: (small: (), medium: 2.1rem);
$quickSearchMerchantFavoriteIconWidth: 2rem;
$quickSearchMerchantFavoriteIconHeight: 2rem;
$quickSearchMerchantRebateElevationOldValueFontWeight: normal;
$quickSearchMerchantRebateElevationOldValueBeforeBorderTop: 1px solid #000;
$quickSearchResultsOverflow: auto;
$quickSearchResultsMaxHeight: (small: 29.5rem);
$quickSearchResultsItemDisplay: block;
$quickSearchContentJustifyContent: (small: flex-start, medium: flex-start);
$quickSearchFooterPadding: 2rem 6rem 2rem 3rem;
$quickSearchFooterBackground: #f5f7f7;
$quickSearchFooterColor: #636363;
$quickSearchFooterBorderRadius: 0;
$quickSearchFooterFontSize: 1.3rem;
$quickSearchFooterProductLinkWrapFontSize: 1.6rem;
$quickSearchFooterProductLinkWrapPadding: 2rem 3rem;
$quickSearchFooterProductLinkWrapBorderTop: ();
$quickSearchFooterProductLinkWrapMargin: 0;
$quickSearchFooterProductLinkColor: $brandPrimaryColor;
$quickSearchFooterProductLinkHoverColor: $brandPrimaryColor;
$quickSearchFooterProductLinkKeywordsFontWeight: bold;
$quickSearchFooterProductLinkWrapBackground: #fff;
$quickSearchOnlineMatchDeactivatedRebatePrefixDisplay: none;

/* Flip Tile */
$flipTileLogoWrapBorderRadius: 0;
$flipTileFavoriteIconOrder: 3;
$flipTileLogoWrapMargin: 0;
$flipTilePremierRebateMargin: 0 auto 0 2rem;
$flipTileButtonWrapPadding: 0 auto 0 2rem;
$flipTileButtonLineHeight: 3.5rem;
$flipTilePremierRebatePadding: 0;
$flipTilePremierRebateTextAlign: left;
$flipTileItemBorderBottom: 1px solid $brandGrayLight;
$flipTileFavoriteIconHeight: (small: 2rem, medium: 2rem);
$flipTileFavoriteIconWidth: (small: 2rem, medium: 2rem);
$flipTileFavoriteIconMargin: 0;
$flipTilePremierRebateInlineElevationNewValueWhiteSpace: normal;
$flipTilePremierRebatePrefixColor: $brandPrimaryColor;
$flipTilePremierRebatePrefixFontWeight: bold;
$flipTilePremierRebateTieredPrefixFirstLetterTextTransform: none;

/* Recent Stores */
$recentStoresPadding: 0;
$recentStoresFlipTileFrontBackground: ();
$recentStoresFlipTileBackBackground: ();
$recentStoresTitleBorderBottom: 1px solid $brandGrayLight;
$recentStoresTitleMargin: 0;
$recentStoresTitlePadding: 2rem 2rem 2rem 3rem;
$recentStoresTitleHeadingMargin: 0;
$recentStoresViewAllLinkBottomFontSize: 1.6rem;
$recentStoresViewAllLinkPadding: 2rem;
$recentStoresTextAlign: left;

/* Recent Stores Flyout */
$recentStoresFlyoutBorderWidth: 2px 0 2px 2px;
$recentStoresFlyoutHeadBgColor: #ffb127;
$recentStoresFlyoutH2Color: #111b40;
$recentStoresFlyoutH2FontSize: 1.4rem;
$recentStoresFlyoutH2FontWeight: bold;
$recentStoresFlyoutMinHeight: 12.6rem;

/* Similar Stores */
$similarStoresTitleMargin: 0;
$similarStoresTitleHeadingMargin: 0;
$similarStoresTitlePadding: 2rem 2rem 2rem 3rem;
$similarStoresTitleBorderBottom: 1px solid $brandGrayLight;
$similarStoresFavoriteIconWidth: (small: 2rem, medium: 2rem);
$similarStoresFavoriteIconHeight: (small: 2rem, medium: 2rem);
$similarStoresFavoriteIconMargin: 0;
$similarStoreFavoriteIconOrder: 3;
$similarStoreLogoMargin: 0;
$similarStoreRebateWrapMargin: 0 auto 0 2rem;
$similarStoreBackground: ();
$similarStoreBorderRadius: ();
$similarStoresPadding: 0;
$similarStoreBorderBottom: 1px solid $brandGrayLight;
$similarStoreLogoPadding: .6rem;
$similarStoreLogoBackground: $brandWhite;
$similarStorePadding: 0 2.4rem 0 1.4rem;
$similarStoreRebatePadding: 0;
$similarStoresRebatePrefixColor: $brandPrimaryColor;
$similarStoresRebatePrefixFontWeight: bold;
$similarStoresRebateOldValueFontSize: 1.4rem;
$similarStoresRebateTieredPrefixFirstLetterTextTransform: none;

/* Search Modal */
$searchModalBackground: #a4a4a4;
$searchModalCloseFontSize: 2rem;
$searchModalRecentOrSimilarStoresSectHeadPadding: 0;
$searchModalRecentOrSimilarStoresHeaderColor: $brandWhite;
$searchModalRecentOrSimilarStoresTileBorderBottom: none;
$searchModalRecentOrSimilarStoresTileLogoWrapMargin:  0 auto 0 1rem;
$searchModalRecentOrSimilarStoresTileRebateMargin: 0;
$searchModalRecentOrSimilarStoresTileRebateColor: $brandWhite;
$searchModalRecentOrSimilarStoresTileRebateValueColor: $brandWhite;
$searchModalRecentOrSimilarStoresTileFavoriteIconWrapOrder: unset;
$searchModalRecentOrSimilarStoresTileFavoriteIconWidth: 1.6rem;
$searchModalRecentOrSimilarStoresSectHeadPadding: .5rem 5%;
$searchModalRecentOrSimilarStoresSectHeadBorderBottom: none;
$searchModalRecentOrSimilarStoresHeaderFontSize: 1.5rem;
$searchModalRecentOrSimilarStoresTilePadding: 0  1.5rem 0 2.2rem;
$searchModalRecentOrSimilarStoresTileRebateFontWeight: bold;
$searchModalRecentOrSimilarStoresTileRebateElevationOldFontSize: 1.4rem;
$searchModalRecentOrSimilarStoresTileRebateElevationOldValueColor: #ccc;
$searchModalRecentOrSimilarStoresTileRebatePadding: 0 0 0 1rem;
$searchModalRecentOrSimilarStoresTileRebateTextAlign: right;
$searchModalQuickSearchMargin: 0 0 2rem 0;
$searchModalQuickSearchDashDisplay: inline;
$searchModalQuickSearchMatchingTitleColor: $brandWhite;
$searchModalQuickSearchMatchingTitleFontSize: 1.5rem;
$searchModalQuickSearchMatchingTitlePadding: .5rem 5%;
$searchModalQuickSearchMatchRebateColor: $brandWhite;
$searchModalQuickSearchMatchMatchTextColor: $brandWhite;
$searchModalQuickSearchMatchHighlightColor: $brandWhite;
$searchModalQuickSearchMatchRebateFontWeight: bold;
$searchModalQuickSearchMatchRebateValueColor: $brandWhite;
$searchModalQuickSearchMatchPadding: 1rem 1rem 1rem 1.5rem;
$searchModalQuickSearchMatchMatchTextMargin: 0;
$searchModalQuickSearchMatchColor: $brandWhite;
$searchModalQuickSearchMatchBackground: none;
$searchModalQuickSearchMatchFontSize: 1.4rem;
$searchModalQuickSearchFavoriteIconWidth: 1.6rem;
$searchModalQuickSearchFavoriteIconHeight: 1.6rem;
$searchModalQuickSearchFavoriteIconMargin: 1rem 0 0 2.2rem;
$searchModalSearchSubmitPositionLeft: auto;
$searchModalSearchInputFontSize: 1.8rem;
$searchModalSearchInputFontWeight: normal;
$searchModalSearchInputFocusBackground: $brandBlack;
$searchModalSearchFormBackground: #414141;
$searchModalProductLinkColor: #fff;
$searchModalQuickSearchItemDisplay: flex;

/* Favorite Stores */
$favoriteStoresMerchantTileHeight: (small: 19rem, medium: 19rem);
$favoriteStoresMerchantTileBorder: (small: 1px solid #e6e7e8, medium: 1px solid #e6e7e8);
$favoriteStoresMerchantTileBorderRadius: 0;
$favoriteStoresMerchantTileBoxShadow: (small: none, medium: none);
$favoriteStoresMerchantTilePillPositionBottom: (small: 5.6rem, medium: 5.6rem);
$favoriteStoresManageFavoritesTileFontWeight: (small: bold, medium: bold);
$favoriteStoresManageFavoritesTileHeight: (small: 100%, medium: 100%);
$favoriteStoresManageFavoritesTileTextAlign: (small: center, medium: left);
$favoriteStoresManageFavoritesTileBackground: (small: $brandNeutralMediumColor, medium: $brandPrimaryColor);
$favoriteStoresManageFavoritesTileBorderRadius: (small: $favoriteStoresMerchantTileBorderRadius, medium: $favoriteStoresMerchantTileBorderRadius);
$favoriteStoresManageFavoritesTileLineHeight: (small: 2.6rem, medium: 3.6rem);
$favoriteStoresManageFavoritesTileColor: (small: $brandNeutralDarkColor, medium: $brandWhite);
$favoriteStoresManageFavoritesTileWrapPadding: (small: .2rem, medium: 0);
$favoriteStoresManageFavoritesTileWrapHeight: (small: 19rem, medium: 19rem);
$favoriteStoresManageFavoritesTileWrapBorder: 1px solid #e6e7e8;
$favoriteStoresManageFavoritesTileTextWidth: 18.2rem;
$favoriteStoresManageFavoritesTileArrowFontFamily: (small: (), medium: 'swa-icon');
$favoriteStoresManageFavoritesTileArrowContent: (small: (), medium: '\e005');
$favoriteStoresManageFavoritesTileArrowFontSize: (small: (), medium: 2.2rem);
$favoriteStoresManageFavoritesTilePadding: (small: 1.5rem 2.5rem, medium: 1.5rem);
$favoriteStoresManageFavoritesTileDotDisplay: inline;
$favoriteStoresPmhpSectionTitleMargin: (small: (), xxlarge: 0 0 5.5rem 0);

/* Bonus Offer */
$bonusMerchantOfferBorder: 1px solid #ccc;
$bonusMerchantOfferBonusTagWrapBackground: $brandGreen;
$bonusMerchantOfferBonusTagWrapColor: $brandWhite;
$bonusMerchantOfferBonusTagWrapHeight: ();
$bonusMerchantOfferBonusTagWrapMinHeight: 2.5rem;
$bonusMerchantOfferBonusTagWrapWidth: 98%;
$bonusMerchantOfferBonusTagWrapMinWidht: ();
$bonusMerchantOfferBottomWrapBackground: $brandNeutralDarkColor;
$bonusMerchantOfferHeadingFontWeight: 500;
$bonusMerchantOfferSeeDetailsSuffixFontWeight: bold;
$bonusMerchantOfferSeeDetailsAfterContent: '\e005';
$bonusMerchantOfferSeeDetailsAfterFontFamily: 'swa-icon';
$bonusMerchantOfferSeeDetailsAfterPadding: 0 0 0 .5rem;
$bonusMerchantOfferSeeDetailsAfterPosition: relative;
$bonusMerchantOfferSeeDetailsAfterPositionBottom: -1px;
$bonusMerchantOfferSeeDetailsAfterFontSize: 1.2rem;
$bonusMerchantOfferSeeDetailsMargin: 0;
$bonusMerchantOfferBottomWrapPadding: 1.3rem;
$bonusMerchantOfferRebatePrefixTextTransform: lowercase;
$bonusMerchantOfferBottomWrapHeight: 19.5rem;

/* Merchant With Offer Tile */
$merchantWithOfferCountBorder: (small: 1px solid #e6e7e8);
$merchantWithOfferCountRebatePositionTop: 159px;
$merchantWithOfferCountRebateHeight: 70px;
$merchantWithOfferCountRebateBackground: (small: #f5f5f5);
$merchantWithOfferCountH3Color: $brandPrimaryColor;
$merchantWithOfferCountH3LineHeight: 2rem;
$merchantWithOfferCountH3FontWeight: normal;
$merchantWithOfferCountFavoriteIconPositionTop: 8px;
$merchantWithOfferCountFavoriteIconPositionRight: 8px;
$merchantWithOfferCountFavoriteIconWidth: 1.95rem;
$merchantWithOfferCountFavoriteIconHeight: 1.65rem;
$merchantWithOfferCountPillWidth: 8.8rem;

/* AdBlock Warning */
$adBlockWarningPadding: 2.6rem 0;
$adBlockWarningMessageJustifyContent: center;
$adBlockWarningMessageTextAlign: left;
$adBlockWarningMessageInnerPadding: 0 3rem;
$adBlockWarningMessagePadding: 0;
$adBlockWarningMessageFontWeight: normal;
$adBlockWarningMessageInnerMaxWidth: 95rem;
$adBlockWarningIconContent: '\e016';
$adBlockWarningIconFontFamily: 'swa-icon';
$adBlockWarningIconFontSize: 3.5rem;
$adBlockWarningIconColor: $brandPrimaryColor;
$adBlockWarningBackground: #fff;

/* How It Works */
$howItWorksSectionPadding: (small: 5rem 0);
$howItWorksSectionTitleMargin: (small: 0 0 1rem 0);
$howItWorksSectionTitleAlignItems: (small: center);
$howItWorksItemTitleFontSize: (small: 2.4rem);
$howItWorksItemTitleLineHeight: (small: 3.2rem);
$howItWorksItemDescriptionColor: (small: $brandTertiaryColor);
$howItWorksItemDescriptionLineHeight: (small: 2.4rem);
$howItWorksVideoLinkColor: #304cb2;
$howItWorksVideoLinkFontSize: 1.6rem;
$howItWorksVideoLinkBeforeContent: '\f144';
$howItWorksVideoLinkBeforeFontFamily: 'fontello';
$howItWorksVideoLinkBeforeDisplay: inline-block;
$howItWorksVideoLinkBeforeFontSize: 2.2rem;
$howItWorksVideoLinkBeforeMargin: 0 1rem .3rem 0;
$howItWorksVideoLinkBeforePosition: relative;
$howItWorksVideoLinkBeforePositionTop: .2rem;
$howItWorksVideoLinkWrapperMargin: (small: 1rem 0, medium: 1rem 0 5rem 0);
$howItWorksInvertedSectionBackground: (small: linear-gradient(180deg, $brandPrimaryColor 0%, $brandQuaternaryColor 100%));
$howItWorksNewMemberSectionTitleH2FontSize: (small: 3.5rem);
$howItWorksNewMemberItemImgWidth: 15.5rem;

/* Testimonials */
$testimonialsSectionBackground: (small: $brandNeutralDarkColor);
$testimonialsIconBeforeBackground: no-repeat center/100% url('~org/assets/images/icons/account.svg'), #ffbf27;
$testimonialsIconBeforeWidth: 7.5rem;
$testimonialsIconBeforeHeight: 7.5rem;
$testimonialsIconBeforeDisplay: block;
$testimonialsIconBorderRadius: 50%;
$testimonialsIconOverflow: hidden;
$testimonialsIconBeforeBackgroundSize: 4.5rem;
$testimonialsStaticSectionPadding: (small: 5rem 0 3rem 0);
$testimonialsSectionTitleH2FontSize: (small: 2.4rem);
$testimonialsSectionTitleH2LineHeight: (small: 2.8rem);
$testimonialsStaticTextColor: $brandWhite;
$testimonialsStaticTextLineHeight: 3.2rem;
$testimonialsStaticTextFontSize: 1.8rem;
$testimonialsStaticItemBorderBottom: 1px solid rgba(186, 197, 210, .25);
$testimonialsStaticItemPadding: 3rem 0;

/* Sticky CTA Bar */
// VHP
$stickyCtaBarVhpContentFlexDirection: (small: column, medium: row);
$stickyCtaBarVhpWrapHeight: (small: (), medium: (), large: (), xlarge: 11.2rem);
$stickyCtaBarVhpBackgroundColor: (small: $brandPrimaryColor);
$stickyCtaBarVhpColor: $brandWhite;
$stickyCtaBarVhpHeaderFontSize: 2.4rem;
$stickyCtaBarVhpHeaderLineHeight: 2.8rem;
$stickyCtaBarVhpHeaderFontWeight: 700;
$stickyCtaBarVhpHeaderColor: $brandWhite;
$stickyCtaBarVhpHeaderMargin: (small: 0 0 2.5rem, medium: 0);
$stickyCtaBarVhpHeaderTextAlign: center;
$stickyCtaBarVhpButtonWidth: (small: 37.5rem, medium: 20rem);
$stickyCtaBarVhpButtonMaxWidth: 100%;
$stickyCtaBarVhpButtonPadding: (small: .8rem);
$stickyCtaBarVhpButtonMargin: (small: (), medium: 0 0 0 2.5rem);

/* Featured Offers */
$featuredOffersLogoSectionTitleTextAlign: center;
$featuredOffersLogoItemTileWidth: 15.4rem;
$featuredOffersLogoItemTileHeight: 17.6rem;
$featuredOffersLogoItemTileBorder: 1px solid #d4d4d0;
$featuredOffersLogoItemTileBoxShadow: 0 2px 4px 0 rgba(255, 255, 255, .5);
$featuredOffersLogoTop: 0;
$featuredOffersLogoBottom: 0;
$featuredOffersLogoRight: 0;
$featuredOffersLogoLeft: 0;
$featuredOffersLogoRebateOldValue: 1.1rem;
$featuredOffersLogoRebateNewValueFontSize: 1.3rem;
$featuredOffersLogoPillPositionBottom: (small: 5.8rem, medium: 5.8rem);
$featuredOffersLogoRebateNewValueLineHeight: 1.6rem;

/* Merchant Logos */
$merchantLogosSectionMargin: (small: 0, medium: 0 0 3rem 0);
$merchantLogosSectionPadding: (small: 2rem 0, medium: 3rem 0);
$merchantLogosListLayoutColumns: (small: none);
$merchantLogosListLayoutColumnGap: (small: 5rem);

/* Onboarding */
$headerOnboardingCongratsTitleDisplay: flex;
$headerOnboardingCongratsTitleFontSize: 3.4rem;
$headerOnboardingCongratsTitleLineHeight: 4.2rem;
$headerOnboardingCongratsTextColor: #333;
$headerOnboardingCongratsIconDisplay: 0 10px 0 0;
$headerOnboardingCongratsH2Color: #3f3f3f;
$onboardingPadding: 0 2rem 2rem;
$onboardingPageCounterPosition: absolute;
$onboardingPageCounterPositionTop: 10px;
$onboardingPageCounterPositionLeft: 2rem;
$onboardingPageCounterPositionRight: auto;
$onboardingPageCounterFontSize: 1.4rem;
$onboardingPageCounterFontWeight: 700;
$onboardingPageCounterColor: #3f3f3f;
$onboardingBrandLogoBackground: url('~org/assets/images/logo.svg') no-repeat center;
$onboardingBrandLogoMargin: (small: 8rem auto 0);
$onboardingWelcomeStepHeaderMargin: (small: 7.7rem 0 2rem, large: 7.7rem 0 1rem);
$onboardingWelcomeStepHeaderDotDisplay: inline;
$onboardingBrandLogoBackgroundSize: (small: 80%, large: 47%);
$onboardingH2Color: #3f3f3f;
$onboardingH2FontSize: (small: 3.2rem, large: 4rem);
$onboardingH2LineHeight: (small: 4.3rem, large: 4.5rem);
$onboardingPFontWeight: 400;
$onboardingPColor: #333;
$onboardingPFontSize: (small: 1.8rem, large: 2rem);
$onboardingBottomContentPadding: (small: 2rem, medium: 2rem);
$onboardingStepButtonLineHeight: 1;
$onboardingStepButtonBorder: none;
$onboardingBottomContentPositionTop: (small: 465px, large: 440px);
$onboardingStepButtonWidth: (small: 100%, large: 24rem);
$onboardingStepButtonMinHeight: 45px;
$onboardingBoxShadowLarge: 0 2px 24px 0 rgba(0, 0, 0, .5);
$onboardingOptInImageBackground: url('~org/assets/images/email.png') center/175px auto no-repeat;
$onboardingOptInImageHeight: 175px;
$onboardingOptInMargin: (small: 7rem 0 0, large: 7rem 0 0);
$onboardingOptInHeaderMargin: (small: 4rem auto 2rem, large: 0 auto 1rem);
$onboardingOptInHeaderPadding: 0 1.5rem;
$onboardingOptInHeaderDotDisplay: inline;
$onboardingOptInTextWidth: auto;
$onboardingOptInTextMargin: 0 3.4rem 2.5rem;
$onboardingOptinLabelColor: #2172ba;
$onboardingOptinLabelFontSize: 1.6rem;
$onboardingOptinLabelFontWeight: 700;
$onboardingOptinLabelTextDecoration: underline;
$onboardingOptinLabelDotDisplay: inline;
$onboardingFavoritesMargin: (small: 4rem 0 0, large: 5.4rem 0 0);
$onboardingFavoritesHeadingFontSize: (small: 2.5rem, large: 4rem);
$onboardingFavoritesHeadingMargin: (small: 0 5rem .9rem, large: 0 auto 1rem);
$onboardingFavoritesHeadingLineHeight: (small: 3rem, large: 4.5rem);
$onboardingFavoritesTextFontSize: 2rem;
$onboardingFavoritesTextMargin: 0 0 2.5rem;
$onboardingFavoritesPickFavoritesMargin: (small: .5rem 0 0 0, large: auto);
$onboardingFavoritesPickFavoritesBorder: 1px solid #979797;
$onboardingFavoritesPickFavoritesBoxShadow: 0 3px 6px 0 rgba(0, 0, 0, .23), 0 3px 6px 0 rgba(0, 0, 0, .16);
$onboardingFavoritesPickFavoritesBorderWidth: (small: 1px 0 1px 0, large: 1px 1px 1px 1px);
$onboardingFavoritesPickFavoritesBackground: (small: #f7f7f7);
$onboardingFavoritesPickFavoritesPadding: (small: 1rem 0 0, large: 2.3rem 2.5rem 0);
$onboardingFavoritesPickFavoritesHeight: (small: 220px, large: 220px);
$onboardingFavoritesMerchantLogoWidth: (small: 160px, large: auto);
$onboardingFavoritesMerchantLogoPadding: (small: 0, large: .1rem);
$onboardingFavoritesMerchantLogoMargin: (small: 0 0 1rem, large: .7rem 1.5rem);
$onboardingFavoritesPotentialFavoriteWidth: 135px;
$onboardingFavoritesPotentialFavoriteHeight: 56px;
$onboardingFavoriteIconFontSize: 1.7rem;
$onboardingFavoriteIconPositionTop: (small: 5px, large: 16px);
$onboardingFavoriteIconPositionRight: (small: 5px, large: 6px);
$onboardingFavoriteIconWidth: 1.8rem;
$onboardingFavoriteIconHeight: (small: 1.8rem, large: 1.5rem);
$onboardingFavoriteStepBottomContentMinHeight: (small: 16rem, large: auto);
$onboardingFavoriteLaterLinkAfterDisplay: none;
$onboardingFavoriteLaterLinkDotDisplay: inline;
$onboardingStepLaterLinkColor: #2172ba;
$onboardingFavoriteStepButtonMargin: (small: 1rem auto 0, large: 0 auto 1rem);
$onboardingFavoriteLaterLinkMargin: (small: 0, medium: ());
$onboardingFavoriteLaterLinkDisplay: (small: inline-block, medium: ());
$onboardingStepLaterLinkMaxWidth: 24rem;
$onboardingTurnOffAlertsColor: #636363;
$onboardingTurnOffFontSize: (small: 1.6rem, large: 1.3rem);
$onboardingTurnOffLineHeight: (small: 2.1rem, large: 1.8rem);
$onboardingFavoritesCarouselArrowNextBeforeContent: '';
$onboardingFavoritesCarouselArrowNextBeforeBackground: url('~core/assets/images/arrow-right.svg') 0 0 no-repeat;
$onboardingFavoritesCarouselArrowPrevBeforeContent: '';
$onboardingFavoritesCarouselArrowPrevBeforeBackground: url('~core/assets/images/arrow-left.svg') 0 0 no-repeat;
$onboardingFavoritesCarouselArrowDisplay: inline-block;
$onboardingFavoritesCarouselArrowHeight: 35px;
$onboardingFavoritesCarouselArrowWidth: 20px;
$onboardingFavoritesCarouselArrowNextPositionRight: -1.8rem;
$onboardingFavoritesCarouselArrowPrevPositionLeft: -1.8rem;
$onboardingGetTheButtonBackground: url('~org/assets/images/swa_button.png') center/18.1rem no-repeat;
$onboardingButtonStepHeadingMargin: (small: 8rem 0 2rem);
$onboardingButtonStepHeadingWidth: (small: auto, large: 712px);
$onboardingButtonStepHeadingFontSize: (small: 3.6rem);
$onboardingButtonStepHeadingLineHeight: (small: 4.4rem);
$onboardingGetTheButtonHeight: (small: 181px);
$onboardingButtonStepBulletTextFontSize: (small: 1.4rem);
$onboardingButtonStepBulletTextFontWeight: 600;
$onboardingButtonStepBulletTextWidth: 363px;
$onboardingButtonStepBulletTextPadding: .9rem 0 .7rem;
$onboardingButtonStepBulletTextBorderBottom: 1px solid rgba(137, 137, 137, .5);
$onboardingButtonStepListPadding: (small: 1rem 0 0 .5rem);
$onboardingButtonStepListMargin: 0 0 2.5rem;
$onboardingButtonStepButtonMargin: (small: 0 auto 1rem);
$onboardingButtonStepLaterLinkAfterDisplay: none;
$onboardingCongratsHeadingFontSize: (small: 3rem, large: 3.4rem);
$onboardingCongratsHeadingColor: #3f3f3f;
$onboardingCongratsHeadingFontSize: (small: 3rem, large: 3rem);
$onboardingCongratsHeadingFontWeight: (small: 400);
$onboardingCongratsHeadingPaddingTop: (small: 8.8rem, large: 2.8rem);

/* Email OptIn Subscribe Modal */
$emailSubscribePanelPadding: 5rem 4rem 5rem 2rem;
$emailSubscribeCloseWidth: (small: 2rem);
$emailSubscribeCloseHeight: (small: 2rem);
$emailSubscribeCloseColor: (small: $brandWhite, medium: $brandWhite);
$emailSubscribePanelBackground: $brandNeutralDarkColor;
$emailSubscribePanelTextAlign: left;
$emailSubscribeHeadingTextAlign: left;
$emailSubscribeHeadingColor: (small: $brandYellow, medium: $brandYellow);
$emailSubscribeContentColor: (small: $brandWhite, medium: $brandWhite);
$emailSubscribeSubmitPadding: (small: 1rem 1.5rem, medium: .8rem 2rem);
$emailSubscribeSubmitFontSize: (small: 2rem, medium: 1.5rem);
$emailSubscribeSubmitMargin: (small: 0 0 1.2rem, medium: 3rem 0 2rem);
$emailSubscribeButtonLabelColor: $brandWhite;
$emailSubscribeButtonLabelFontSize: 2rem;
$emailSubscribeModalPositionTop: 30%;
$emailSubscribeModalMinHeight: auto;
$emailSubscribeModalBackground: $brandNeutralDarkColor;
$emailSubscribeModalWidth: (small: initial, medium: 70rem);
$emailSubscribeHeadingFont: (small: bold 3.5rem/1.2 $brandH1FontFamily, medium: bold 4rem/1.2 $brandH1FontFamily);
$emailSubscribeModalPadding: 11rem 7rem 11rem 11rem;
$emailSubscribeHeadingMargin: (small: 0 0 1rem, medium: 0 0 .5rem);
$emailSubscribeContentFont: (small: normal 2.2rem/1.4 $brandFontFamily, medium: normal 1.8rem/1.4 $brandFontFamily);
$emailSubscribeSubmitLineHeight: 1;
$emailSubscribeSubmitBorder: none;
$emailSubscribeCancelLinkDisplay: (small: initial, medium: block);
$emailSubscribeCancelLinkColor: (small: $brandWhite, medium: $brandWhite);
$emailSubscribeCancelLinkFont: (small: normal 2rem/1.4 $brandFontFamily, medium: normal 1.7rem/1.4 $brandFontFamily);
$emailSubscribeCancelLinkLabelIconDisplay: none;
$emailSubscribeCancelLinkLabelAfterDisplay: inline-block;
$emailSubscribeCancelLinkLabelAfterContent: '\e005';
$emailSubscribeCancelLinkLabelAfterFontFamily: 'swa-icon';

/* Personalized Top Offers */
$personalizedTopOffersButtonBorder: 1px solid $brandPrimaryColor;
$personalizedTopOffersButtonColor: $brandPrimaryColor;
$personalizedTopOffersButtonHoverColor: $featuredDealsButtonColor;
$personalizedTopOffersButtonBackground: none;
$personalizedTopOfferssButtonBoxShadow: 0 1px 0 0 #8f8f8f;

/* Trending Stores */
$trendingStoresDividerBackground: rgba(0, 0, 0, .2);
$trendingStoresH2TextAlign: (small: center, medium: left);
$trendingStoresMerchantLinkTileFavoriteIconPositionTop: (small: (), medium: 0);
$trendingStoresMerchantLinkTileFavoriteIconPositionRight: (small: (), medium: 0);
$trendingStoresMerchantLinkTileRebatePositionTop: (small: (), medium: 10.7rem);
$trendingStoresMerchantLinkTileRebatePadding: (small: .7rem 0 0 0);
// New Member View overwriting
$trendingStoresNewMemberBackground: #294299;
// VHP View overwriting
$trendingStoresVhpMerchantLinkTileWidth: (small: 15.4rem, medium: (), large: 100%);
$trendingStoresVhpMerchantLinkTileHeight: (small: 18.4rem, medium: (), large: 100%);
$trendingStoresVhpMerchantLinkTilePillTop: (small: 55%, medium: (), large: ());
$trendingStoresVhpMerchantLinkTileRebatePositionTop: (small: 10.7rem, medium: (), large: ());
$trendingStoresVhpMerchantLinkTileOffersCountDisplay: (small: none, medium: (), large: ());

/* Trending And Seasonal Offers */
$trendingAndSeasonalOffersSectionPadding: (small: 0);

/* Button Overlay */
$buttonOverlayContentBackground: url('~org/assets/images/button_overlay_background.png') no-repeat center;
$buttonOverlayBlockOnePositionTop: 33rem;
$buttonOverlayBlockTwoPositionTop: 68rem;
$buttonOverlayBlockThreePositionTop: 124rem;
$buttonOverlayBlockFourPositionTop: 218rem;
$buttonOverlayBlockTwoPadding: 0 10rem;
$buttonOverlayBlockThreePadding: 0 8rem;
$buttonOverlayBlockFourPadding: 0 8rem;
$buttonOverlayMottoFontSize: 1.8rem;
$buttonOverlayMottoFontWeight: bold;
$buttonOverlayMottoLetterSpacing: -.5px;
$buttonOverlayMottoColor: $brandNeutralDarkColor;
$buttonOverlayBlockThreeHeaderColor: $brandWhite;
$buttonOverlayHiwHintsColor: $brandWhite;
$buttonOverlayHeaderLetterSpacing: -.42px;
$buttonOverlayButtonBoxShadow: 0 1px 0 0 #8f8f8f;
$buttonOverlayButtonBorder: none;
$buttonOverlayRatingMargin: 2rem 0 1.5rem 0;
$buttonOverlayEarningNoteColor: #636363;
$buttonOverlayEarningNoteFontSize: 1rem;
$buttonOverlayEarningNoteLetterSpacing: .25px;
$buttonOverlayEarningNoteLineHeight: 2.2rem;
$buttonOverlayEarningNoteTextAlign: center;
$buttonOverlayHeaderMargin: 1rem 0;
$buttonOverlayHiwHintsBlockOneTextMarginTop: 2rem;
$buttonOverlayHiwHintsSubheaderMargin: 1rem 0;
$buttonOverlayHiwHintsMargin: 25rem 0 3rem 0;
$buttonOverlayHiwHintsBorder: 1px solid rgba(255, 255, 255, .2);
$buttonOverlayScrollbarTrackPieceBackground: #e6e7e8;
$buttonOverlayScrollbarThumBackground: #8f8f8f;
$buttonOverlayHiwHintsTextMargin: 0 0 1rem 0;

/* Button Benefits Home Page */
$homeButtonBenefitsSectionPadding: (small: 0);
$homeButtonBenefitsHeaderMargin: (small: 0 0 1.5rem 0, medium: 1rem 0 1.5rem 0);
$homeButtonBenefitsHeaderFontSize: 3rem;
$homeButtonBenefitsHeaderLineHeight: 4rem;
$homeButtonBenefitsHeaderMinWidth: (small: 0);
$homeButtonBenefitsListFontSize: 1.6rem;
$homeButtonBenefitsListLineHeight: 3.2rem;
$homeButtonBenefitsListColor: $brandNeutralDarkColor;
$homeButtonBenefitsListMargin: 0 0 1.5rem;

/* Gift Guide */
$giftGuideSectionPadding: (small: 4rem 0 2rem 0, medium: 0);
$giftGuideBackground: (small: #f5f5f5, medium: unset);
$giftGuideInnerPadding: (small: (), medium: 3rem 1rem);
$giftGuideInnerBackground: (small: unset, medium: #f5f5f5);
$giftGuideButtonColor: $brandNeutralDarkColor;
$giftGuideButtonBoxShadow: 0 1px 0 0 #8f8f8f;
$giftGuideItemMerchantLogoBorderRadius: 0;
$giftGuideItemMerchantLogoAfterBorderRadius: 0;
$giftGuideItemMerchantWrapBorder: 1px solid #e6e7e8;
$giftGuideItemFavoriteIconSvgPathFill: $brandPrimaryColor;
$giftGuideItemOfferDetailsLineHeight: 20px;
$giftGuideItemOfferDetailsColor: $brandTextColor2;
$giftGuideItemRebatePrefixColor: $brandPrimaryColor;
$giftGuideItemRebatePrefixFontWeight: bold;
$giftGuideItemTieredPrefixDisplay: none;
$giftGuideItemRebateWrapBottom: 10px;
$giftGuideItemPromoCodeColor: #1c7bd9;
$giftGuideItemPromoCodeFontWeight: bold;
$giftGuideItemPromoCodeLineHeight: 1.6rem;
$giftGuideItemPromoCodeLetterSpacing: -.5px;
$giftGuideItemExpirationDateColor: $brandTextColor2;
$giftGuideItemExpirationDateFontSize: 1rem;
$giftGuideItemExpirationDateLineHeight: 1.6rem;
$giftGuideItemExpirationDateMargin: -.5rem 0;

/* Holiday Border */
$holidayWrapBackground: $brandNeutralDarkColor;

// Home Page
$homePageMhpTopWrapBackground: (small: $brandGradientColor);
$homePageMhpSectionTitleFlexDirection: (small: column);

/* Carousel */
$carouselClippedViewportValue: 2rem;
$carouselDotBackground: unset;
$carouselDotWidth: 1rem;
$carouselDotHeight: 1rem;
$carouselDotBoxShadow: inset 0 0 0 .1rem $brandVioletLight;
$carouselDotActiveBackground: $brandVioletLight;
$carouselInvertedDotActiveBackground: $brandVioletLight;
$carouselInvertedDotBoxShadow: inset 0 0 0 .1rem  $brandVioletLight;
$carouselInvertedDotActiveBoxShadow: none;
$carouselArrowFontFamily: 'swa-icon';
$carouselArrowPrevAfterContent: '\e004';
$carouselArrowNextAfterContent: '\e005';
$carouselArrowFontSize: 1.2rem;
$carouselViewportArrowColor: #ccc;
$carouselCounterColor: $brandNeutralDarkColor;
$carouselCounterArrowFontSize: 1.3rem;
$carouselCounterArrowColor: $brandNeutralDarkColor;
$carouselCounterNumberFontSize: 1.5rem;
$carouselCounterNumberFontWeight: bold;

/* DNT Mobile Warning */
$dntMobileWarningWrapBorderColor: #c30019;
$dntMobileWarningTextColor: $brandWhite;
$dntMobileWarningIconColor: $brandWhite;
$dntMobileWarningIconWidth: 1.9rem;
$dntMobileWarningIconHeight: 1.9rem;
$dntMobileWarningIconBackground: #c30019;
$dntMobileWarningTextFontSize: 1.1rem;
$dntMobileWarningIconPositionLeft: 1.1rem;
$dntMobileWarningTextMargin: 0 0 0 2.5rem;
$dntMobileWarningTextTextAlign: left;
$dntMobileWarningWrapOldSearchModalMargin: 0 .5rem .5rem;
$dntMobileWarningWrapOldSearchModalPadding: 0 0 .6rem;
$dntMobileWarningOldSearchModalFavoriteIconPositionTop: .3rem;
$dntMobileWarningWrapOldSearchDropdownBackground: #f5f7f7;
$dntMobileWarningWrapOldSearchDropdownMargin: 0 .5rem .5rem;
$dntMobileWarningWrapSearchResultsMargin: 0 .5rem;
$dntMobileWarningTextOldSearchDropdownMargin: 0 1rem 0 1rem;
$dntMobileWarningWrapOldSearchDropdownMatchPadding: .8rem 6rem .8rem 1.2rem;
$dntMobileWarningWrapOldSearchDropdownMatchBorderBottom: none;
$dntMobileWarningIconOldSearchDropdownPositionLeft: 0;
$dntMobileWarningContentOldSearchDropdownFlexDirection: row-reverse;
$dntMobileWarningTextOldSearchDropdownColor: $brandTextColor2;
$dntMobileWarningIconMargin: .7rem 0 0;
$dntMobileWarningUlOldSearchDropdownFirstChildWrapPaddingTop: .5rem;
$dntMobileWarningIconExclamationFontWeight: 500;
